import { forkJoin, from, Observable, Subscription } from 'rxjs';
import { decryptAlbum, decryptArtwork } from '../components/private/utlls';
import { PrivateAlbumServiceModel } from './models';
import { AbstractPrivateAlbumService } from './models/abstracts';
import {
	Album,
	ApiError,
	Artwork,
	EncryptedAlbum,
	EncryptedArtwork,
} from './models/api-models';
import { Error as ErrorModel } from './models/error-models';
import { getArtworkRequestObservables, handleApiError } from './utils';

// TODO: Implement password persistance for an album that has been obtained previously (sessionState)
export class PrivateAlbumService extends AbstractPrivateAlbumService
	implements PrivateAlbumServiceModel {
	_password = '';

	constructor() {
		super();
	}

	getPassword = () => this._password;
	setPassword = (password: string) => {
		this._password = password;
	};

	addAlbumToCache(album: Album<Artwork>): void {
		// NOTES: See public album same method
		const albumIndex = this.albumsCache.findIndex(
			(cachedAlbum) => cachedAlbum.uuid === album.uuid
		);

		if (albumIndex + 1) {
			this.albumsCache[albumIndex < 0 ? 0 : albumIndex] = album;
		} else {
			this.albumsCache.push(album);
		}
	}

	getAlbumObs = (
		albumUuid: string,
		withArtworks: boolean
	): Observable<Album<Artwork>> => {
		return new Observable((subscriber) => {
			const cachedAlbum = this.albumsCache.find(
				(album) => album.uuid === albumUuid
			);

			if (!cachedAlbum) {
				// NOTE: First api call to retreive album data.
				const subscription: Subscription = this.apiService
					.getPrivateAlbum(albumUuid, withArtworks)
					.subscribe(
						(encryptedAlbum: EncryptedAlbum | ApiError) => {
							const password = this.getPassword();

							if ((encryptedAlbum as ApiError).errorId) {
								console.warn('API error on fetching ');

								subscriber.error();

								return;
							}

							encryptedAlbum = encryptedAlbum as EncryptedAlbum;

							const decryptedAlbum: Album<
								EncryptedArtwork
							> | null = decryptAlbum(encryptedAlbum, password);

							if (!decryptedAlbum) {
								console.warn(
									ErrorModel[ErrorModel.UnableToDecryptPrivateAlbum]
								);

								subscriber.error(ErrorModel.UnableToDecryptPrivateAlbum);

								return;
							}

							const decryptedArtworks: any[] = encryptedAlbum.artworks.map(
								(encryptedArtwork) => decryptArtwork(encryptedArtwork, password)
							);

							const album = {
								...decryptedAlbum,
								artworks: decryptedArtworks,
							};

							// NOTE: Update cache. Simply return found album after cache insertion.
							this.addAlbumToCache(album);

							subscriber.next(album);

							subscriber.complete();

							subscription.unsubscribe();
						},
						// TODO: Create error model
						(error: Error) => {
							subscriber.error(error);
							subscription.unsubscribe();
						}
					);
			} else {
				subscriber.next(cachedAlbum);
				subscriber.complete();
			}
		});
	};

	// NOTE: Used to extract artwork details
	// NOTE: Observabe types mimics Details.tsx data handling
	getArtworkObs(
		albumUuid: string,
		artworkId: string
	): Observable<[Album<Artwork>, Artwork]> {
		return new Observable((subscriber) => {
			// NOTE: Same as per public service (albumCache is decrypted)
			let album: Album<Artwork> | undefined;
			let artwork: Artwork | undefined;

			// NOTE: Check cache first to see if we have albumCached
			album = this.albumsCache.find((album) => album.uuid === albumUuid);

			if (album) {
				// NOTE: Check cache for artwork
				artwork = album.artworks.find(
					(artwork) => artwork && artwork._id === artworkId
				);
			}

			// NOTE: We are safe to extract data from albumsCache
			if (album && artwork) {
				subscriber.next([album, artwork]);
				subscriber.complete();

				return;
			}

			// NOTE: Cover initial load scenario aka user uses artwork link to load application.
			// 		 At this point either album or artwork are loaded
			const subscription = from(
				forkJoin([
					this.getAlbumObs(albumUuid, false),
					this.apiService.getPrivateArtwork(artworkId),
				])
			).subscribe(
				(data: any) => {
					// NOTE: Error handling should happen on ApiService call and be propagated as error()
					const artworkError = handleApiError(data[1]);

					if (artworkError) {
						subscriber.error([artworkError]);

						subscription.unsubscribe();

						return;
					}

					const password = this.getPassword();

					const album = data[0] as Album<Artwork>;
					const encryptedArtwork = data[1] as EncryptedArtwork;

					const decryptedArtwork = decryptArtwork(encryptedArtwork, password);

					if (!decryptedArtwork) {
						console.warn('Unable to decrypt artwork', artworkId);

						subscriber.error();

						return;
					}

					album.artworks[decryptedArtwork.artworkOffset] = decryptedArtwork;

					this.addAlbumToCache(album);

					subscriber.next([album, decryptedArtwork]);
					subscriber.complete();

					subscription.unsubscribe();
				},
				(error: any) => {
					console.warn(
						'API error on getting artworks details',
						albumUuid,
						artworkId
					);
					subscriber.error();
				}
			);
		});
	}

	getAlbumArtworksObs = (
		albumUuid: string,
		limit: number,
		// NOTE: (1) When changing artworks on details we want to load next artwork from specific index only.
		initialSkip: number = 0,
		// NOTE: (2) When changing artworks on detaisl we want to load previous artwork till current only.
		topSentinelIndex: number | null
	): Observable<Album<Artwork | undefined>> => {
		// NOTE: Triggered from load more button. Assumption that given album exists (it has been loaded and is present in cache)
		let cachedAlbum = this.albumsCache.find(
			(album) => album.uuid === albumUuid
		);

		if (!cachedAlbum) {
			throw Error('Loading artworks to unexisting album');
		}

		cachedAlbum = cachedAlbum as Album<Artwork>;

		const apiCalls = getArtworkRequestObservables(
			albumUuid,
			cachedAlbum.artworks,
			initialSkip,
			limit,
			this.apiService.getPrivateAlbumArtworks,
			topSentinelIndex
		);

		return new Observable((subscriber) => {
			const subscription = forkJoin(apiCalls).subscribe(
				(result: any) => {
					result = result as (EncryptedArtwork[] | ApiError)[];

					result.filter((element: Artwork[] | ApiError) => {
						const error = handleApiError(element);

						if (error) {
							console.warn(ErrorModel[error]);

							return false;
						}

						return true;
					});

					const encryptedArtworks = result as EncryptedArtwork[][];

					const decryptedArtworks = encryptedArtworks
						.flat()
						.map((encryptedArtwork) => {
							return decryptArtwork(encryptedArtwork, this.getPassword());
						})
						.filter((item) => {
							return !!item;
						}) as Artwork[];

					decryptedArtworks.forEach((artwork) => {
						cachedAlbum!.artworks[artwork.artworkOffset] = artwork;
					});

					this.addAlbumToCache(cachedAlbum!);

					subscriber.next(cachedAlbum);
					subscriber.complete();

					subscription.unsubscribe();
				},
				(error: ApiError) => {
					subscriber.error(handleApiError(error));
				}
			);
		});
	};
}
