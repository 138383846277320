import styled from 'styled-components';
import {
	Display,
	FlexDirection,
	JustifyContent,
	AlignItems,
} from '../../../ads-components/models';
import Breakpoints from '../../../styles/Breakpoints';

export const componentStyle = {
	wrapper: {
		padding: '0 6vw 2vh 6vw',
		overflow: 'auto',
		height: '100%',
	},
	viewStateIndicator: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		flex: '1',
	},
	noArtworks: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		flex: '1',
		height: '100%',
	},
};


const ListContainer = styled.div`
	position: sticky;

	@media all and  (max-width: ${Breakpoints.desktop})  {
		padding: 2vh 3vw !important;
	}

	@media all and (min-width: ${Breakpoints.tablet}) {
		:before {
			display: inline-block;
			content: '';
			width: 100%;
			height: 0.5rem;
			background: #fff;
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	@media all and (max-width: ${Breakpoints.phone}) {
		padding: 0 !important;
		margin-top: 0;
	}

`;


export {
	ListContainer,
}