import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { Album, Artwork } from '../api-models';

export abstract class AbstractPublicAlbumService {
	// TODO: Find common ground between those to and investigate if merge into single ds is viable
	// NOTE: Fully loaded 'home page' albums
	protected albumsCache: Album<Artwork>[] = [];
	// TODO: Is it that different from albumsCache?
	protected albumPreviewsCache: Album<Artwork>[] = [];

	protected apiService: ApiService = new ApiService();

	abstract getAlbumPreviewsObs(loadMore: boolean): Observable<Album<Artwork>[]>;
	abstract addAlbumPreviewsToCache(albums: any): void;
	abstract getAlbumObs(albumUuid: string): Observable<Album<Artwork>>;
	abstract addAlbumToCache(album: Album<Artwork>): void;
	abstract getAlbumArtworksObs(
		albumUuid: string,
		limit: number,
		initialSkip: number,
		topSentinelIndex: number | null
	): Observable<Album<Artwork | undefined>>;
}
