import ReactModal from 'react-modal';
import styled from 'styled-components';
import { textColor } from '../../../styles';
import Breakpoints from '../../../styles/Breakpoints';

const overlayStyles = {
	...ReactModal.defaultStyles.overlay,
	backgroundColor: 'rgba(5,14,15,0.8)',
	zIndex: 100,
};

const basicContent: any = {
	top: '50%',
	left: '50%',
	right: 'auto',
	bottom: 'auto',
	marginRight: '-50%',
	transform: 'translate(-50%, -50%)',
	boxShadow: '0 0 24px 0 rgba(0,0,0,0.4)',
	maxHeight: '65vh',
	maxWidth: '71rem',
	display: 'flex',
	flexDirection: 'column',
	overflow: 'none',
};

const mediumContent = {
	...ReactModal.defaultStyles.content,
	...basicContent,
	minHeight: '230px',
	overflow: 'none',
};

export const mediumStyles = {
	overlay: overlayStyles,
	content: mediumContent,
};

const ModalContainer = styled.div`
	display: flex;
	font-size: 1.4rem;
	line-height: 1.6rem;
	width: ${Breakpoints.tablet};

	@media all and (max-width: ${Breakpoints.tablet}) {
		width: 65rem;
	}

	@media all and (max-width: ${Breakpoints.phone}) {
		width: 100%;
	}

	#left-column {
		background: #f9f9f9;
		padding: 3.3rem;
		min-width: 24rem;
		max-width: 24rem;

		@media all and (max-width: ${Breakpoints.tablet}) {
			padding: 2.3rem;
		}

		@media all and (max-width: 766px) {
			display: none;
		}
	}

	#right-column {
		padding: 3rem 3.4rem;

		@media all and (max-width: ${Breakpoints.tablet}) {
			padding: 3rem 2.4rem;
		}

		@media all and (max-width: ${Breakpoints.phone}) {
			padding: 2rem 1rem;
			width: 100%;
		}
	}
`;
const CloseButton = styled.button`
	position: absolute;
	right: 2.5rem;
`;

const ImageContainer = styled.div`
	@media all and (max-width: ${Breakpoints.tablet}) {
		width: 12rem;
	}

	img {
		max-width: 16rem;

		@media all and (max-width: ${Breakpoints.tablet}) {
			max-width: -webkit-fill-available;
		}
	}
`;

const ArtworkAuthor = styled.div`
	color: #1e1f21;
	font-weight: bold;
	margin: 0.8rem 0 0.4rem;
`;

const ArtworkCreationDate = styled.span`
	color: #1e1f21;
`;

const ColumnHeader = styled.div`
    display: flex;
    
    h1 {
        color: ${textColor};
        font-size: 2.5rem;
    }

    @media all and (max-width: ${Breakpoints.phone}) {
        font-weight: bold;
        }
    }
`;

const InputContainer = styled.div`
	margin-right: 0.8rem;
	flex: 1;

	@media all and (max-width: ${Breakpoints.phone}) {
		margin: 0 0 2rem 0;
	}
`;

const ButtonContainer = styled.div`
	float: right;
	padding-top: 1.6rem;
	& > button {
		border-radius: 25px;

		@media all and (max-width: ${Breakpoints.phone}) {
			height: 4.2rem;
		}
	}
`;

const InputWrapper = styled.div`
	width: 40rem;

	@media all and (max-width: ${Breakpoints.tablet}) {
		width: fit-content;
	}

	@media all and (max-width: ${Breakpoints.phone}) {
		width: 100%;
	}
`;

const Input = styled.div`
	display: flex;
	padding: 4rem 0 2rem;

	@media all and (max-width: ${Breakpoints.phone}) {
		display: block;
	}
`;

export {
	CloseButton,
	ImageContainer,
	ModalContainer,
	ArtworkAuthor,
	ArtworkCreationDate,
	ColumnHeader,
	InputContainer,
	ButtonContainer,
	InputWrapper,
	Input,
};
