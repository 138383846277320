import React, { useContext, useEffect, useState } from 'react';
import { ViewState } from '../../../../models';
import { Album, Artwork } from '../../../../services/models/api-models';
import { AppContext } from '../../../../context/context';
import { useHistory, useParams } from 'react-router-dom';
import { LogoIndicator } from '../../../../ads-components/indicators/logo-loading-indicator/LogoLoadingIndicator';
import { defaultLogoIndicatorSize } from '../../../../styles';
import { Effect } from '../../../../ads-components/indicators/logo-loading-indicator/models';
import { getAlbumView } from '../../../home/utils';
import { Subscription } from 'rxjs';

interface LocationState {
	showroom: Album<Artwork> | undefined,
	returnPath: string | undefined,
}

export function PrivateHome(): JSX.Element {
	const componentSubs = new Subscription();
	const context = useContext(AppContext);
	const [viewState, setViewState] = useState<ViewState>(ViewState.Loading);
	const [showroom, setShowroom] = useState<Album<Artwork> | null>(null);
	const history = useHistory<LocationState>();
	const params = useParams<{uuid: string | undefined}>();

	useEffect(() => {
		if(history.location.state && history.location.state.showroom) {
			// NOTE: here we verify whether showroom hasn't been unpublished
			setViewState(ViewState.Loading);
			componentSubs.add(context.showroomService.privateShowroomExistsObs(history.location.state.showroom.uuid).subscribe(
				() => {
					setShowroom(history.location.state.showroom as Album<Artwork>);
					setViewState(ViewState.Ok);
				},
				() => { history.push('/private/404'); },
			))
		} else {
			history.push(`/private/${params.uuid}/login`, {
				showroom: undefined,
				returnPath: `/private/${params.uuid}`
			});
		}
	}, [history])

	useEffect(() => {
		return () => {
			if(componentSubs) {
				componentSubs.unsubscribe();
			}
		}
	}, []);

	function loadMoreArtworks(){}

	return (
		<>
			{(viewState === ViewState.Loading
				|| viewState === ViewState.Error) && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						flex: '1',
					}}>
					<LogoIndicator
						width={defaultLogoIndicatorSize}
						effect={
							viewState === ViewState.Loading
								? Effect.Pulse
								: Effect.Static
						}>
						{viewState}
					</LogoIndicator>
				</div>
			)}
			{viewState === ViewState.Ok && (
				getAlbumView(
					context.viewportType,
					showroom as Album<Artwork>,
					loadMoreArtworks,
					true,
				)
			)}
		</>
	);
}
