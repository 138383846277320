export enum Visibility {
	MozInitian = '-moz-initial',
	Inherit = 'inherit',
	Initial = 'initial',
	Revert = 'revert',
	Unset = 'unset',
	Hidden = 'hidden',
	Collapse = 'collapse',
	Visible = 'visible',
}
