import * as React from 'react';
import { Props } from '../models';
import { componentStyle as cs } from '../styles';
import { ArtworkDescriptionBar } from '../../../bars/description-bars/artwork-description/ArtworkDescriptionBar';
import { StaticImageBox } from '../../static-image-box/StaticImageBox';
import { Measurement } from '../../static-image-box/models';

export function StaticArtworkSnapshotBox(props: Props) {
	return (
		<div style={cs.wrapper}>
			<div style={cs.imageBox}>
				<StaticImageBox
					imageSrc={props.imageSrc}
					measurement={Measurement.Width}></StaticImageBox>
			</div>

			<ArtworkDescriptionBar {...props.description}></ArtworkDescriptionBar>
		</div>
	);
}
