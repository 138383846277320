export enum FlexDirection {
    Column = 'column',
    ColumnReverse = 'column-reverse',
    Row = 'row',
    RowReverse = 'row-reverse',
    Inherit = 'inherit',
    Initial = 'initial',
    Revert = 'revert',
    Unset = 'unset',
    MozInitial = '-moz-initial'    
}