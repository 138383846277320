export enum ArtworkDataSection {
	BASIC_DETAILS = 'basicDetails',
	MATERIALS_TECHNIQUES = 'materialsTechniques',
	EDITION = 'edition',
	INSCRIPTION_MARKS = 'inscriptionMarks',
	DIMENSIONS = 'dimensions',
	PROVENANCE = 'provenance',
	LITERATURE = 'literature',
	EXHIBITIONS = 'exhibitions',
	DESCRIPTION = 'description'
}
