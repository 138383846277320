import { Display, Cursor } from '../../../../models';

export const containerStyle = {
	wrapper: {
		display: Display.Flex,
		color: 'gray',
	},
	image: {
		marginRight: '0.5rem',
	},
	clickableImage: {
		marginRight: '0.5rem',
		cursor: Cursor.Pointer,
	},
};
