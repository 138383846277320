export enum WebkitBoxOrient {
  Vertical = "vertical",
  MozInitial = "-moz-initial",
  Inherit = "inherit",
  Initial = "initial",
  Revert = "revert",
  Unset = "unset",
  BlockAxis = "block-axis",
  Horizontal = "horizontal",
  InlineAxis = "inline-axis"
}
