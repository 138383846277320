import { ViewportType } from '../models';

export function isSpecificDevice(
	width: number,
	height: number
): ViewportType | null {
	// NOTE: IPad Pro/ IPad poc for adaptive font size
	if (
		(height === 1024 && width === 768) ||
		(height === 1366 && width === 1024)
	) {
		return ViewportType.MediumPortrait;
	} else if (
		(height === 1024 && width === 1366) ||
		(height === 768 && width === 1024)
	) {
		return ViewportType.MediumLandscape;
	}

	return null;
}
