import React from 'react';
import { Props, Effect } from './models';
import { ComponentStyle } from './style';

const logoUrl = '/images/logo_arteia_round.jpg';

export class LogoIndicator extends React.Component<
	React.PropsWithChildren<Props>
> {
	imageRef = React.createRef<HTMLImageElement>();
	effectIntervalId: number = 0;

	setImage = function (
		effect: Effect,
		imageElement: HTMLImageElement,
		intervalId: number
	): number {
		intervalId && window.clearInterval(intervalId);

		if (effect === Effect.Pulse) {
			let opacity = 1;
			let direction = 1;

			return window.setInterval(() => {
				if (opacity >= 0.9) {
					direction = 0;
				} else if (opacity <= 0.2) {
					direction = 1;
				}

				!direction ? (opacity -= 0.05) : (opacity += 0.05);

				imageElement.style.setProperty('opacity', opacity.toString());
			}, 100);
		} else {
			return intervalId;
		}
	};

	componentDidMount() {
		this.effectIntervalId = this.setImage(
			this.props.effect,
			this.imageRef.current!,
			this.effectIntervalId
		);
	}

	componentDidUpdate() {
		this.effectIntervalId = this.setImage(
			this.props.effect,
			this.imageRef.current!,
			this.effectIntervalId
		);
	}

	componentWillUnmount() {
		window.clearInterval(this.effectIntervalId);
	}

	render() {
		return (
			<>
				<img
					ref={this.imageRef}
					src={logoUrl}
					style={new ComponentStyle(this.props.width, '1')}
				/>
				<h2>{this.props.children}</h2>
			</>
		);
	}
}
