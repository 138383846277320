export enum FontWeight {
    MozInitial = '-moz-initial',
    Inherit = 'inherit',
    Initial = 'initial',
    Revert = 'revert',
    Unset = 'unset',
    Normal = 'normal',
    Bold = 'bold',
    Bolder = 'bolder',
    lighter = 'lighter'
}