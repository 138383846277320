import { Props, State } from '../models';
import { getPreviews, getPrivatePreview, getPublicPreview } from '.';
import { Measurement } from '../../../ads-components/boxes/static-image-box/models';
import { missingArtworkIndicator } from '../../generic';
import { getImageAssetUrl } from '../../../utils';

// TODO: When styles are stable and modeled update cs param type
export function updateDetailsState(
	this: React.Component<Props, State>,
	cs: any,
	previewImagesRowHeight: string,
	measurement: Measurement
) {
	let previewImageSrc =
		this.props.artwork.previews[this.props.previewIndex] &&
		this.props.artwork.previews[this.props.previewIndex].small;

	// NOTE: When changing artworks preview is reseted to 0 but a given artwork might not have any preview at all.
	if (!previewImageSrc) {
		previewImageSrc = '';
	}

	this.setState({
		previews: getPreviews.call(this, cs.previewBox, previewImagesRowHeight),
		preview: getPublicPreview(
			previewImageSrc
				? getImageAssetUrl(previewImageSrc)
				: missingArtworkIndicator,
			measurement
		),
	});
}
