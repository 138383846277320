import { Button, LabeledInput, LabeledTextarea, toast } from 'ads';
import {
	Background,
	ButtonSize,
	Color,
	Resize,
} from 'ads/dist/src/components/models';
import React, { ChangeEvent, useState } from 'react';
import { TextButtonContainer } from '../top-bar/styles';
import CloseIcon from '../../icons/ico-showroom-close.svg';
import ReactModal from 'react-modal';
import {
	ButtonContainer,
	CloseButton,
	ColumnHeader,
	Input,
	InputContainer,
	InputWrapper,
	mediumStyles,
	ModalContainer,
} from './styles';
import { Field, InquireModalField, InquireModalFields, Props } from './models';
import { StaticArtworkSnapshotBox } from '../../ads-components/boxes/artwork-snapshot-box/static/StaticArtworkSnapshotBox';
import {
	formatArtworkCreationDate,
	getArtworkAuthors,
	getArtworkCreationYear,
	getArtworkImageSrc,
	getArtworkTitles,
	getImageAssetUrl,
	isEmailValid,
} from '../../utils';
import { fieldsInitialState } from './config';
import { MailService } from './services';
import { ViewState } from '../../models';
import { Loader } from '../loader';

export function InquireModal(props: Props): JSX.Element {
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
	const [fields, setFields] = useState<InquireModalFields>(fieldsInitialState);
	const [viewState, setViewState] = useState(ViewState.Ok);

	function closeModal() {
		setModalIsOpen((prev) => !prev);
		setFields(fieldsInitialState);
	}

	function updateFields(field: InquireModalField, value: Field<any>) {
		setFields((prev) => ({
			...prev,
			[field]: value,
		}));
	}

	function onSubmit() {
		setViewState(ViewState.Loading);
		const updatedFields: InquireModalFields = { ...fields };

		const emailValid: boolean = isEmailValid(
			updatedFields[InquireModalField.email].value
		);
		updatedFields[InquireModalField.email].valid = emailValid;
		updatedFields[InquireModalField.email].errorMessage = emailValid
			? null
			: 'Invalid email';

		const messageValid: boolean =
			updatedFields[InquireModalField.message].value !== '';
		updatedFields[InquireModalField.message].valid = messageValid;
		updatedFields[InquireModalField.message].errorMessage = messageValid
			? null
			: 'Field is required';

		setFields(updatedFields);

		if (
			Object.keys(updatedFields)
				.map((key: string) => updatedFields[key as InquireModalField].valid)
				.includes(false)
		) {
			setViewState(ViewState.Ok);
			return;
		} else {
			const sendMailSubs = MailService.sendMailObs({
				name: fields[InquireModalField.name].value,
				phone: fields[InquireModalField.phone].value,
				requesterEmail: fields[InquireModalField.email].value,
				receiverEmail: props.artwork.inquireEmail as string,
				subject: `Inquire request - artwork "${getArtworkAuthors(props.artwork)} - ${getArtworkTitles(props.artwork)}"`,
				message: fields[InquireModalField.message].value,
				artwork: {
					titles: getArtworkTitles(props.artwork),
					authors: getArtworkAuthors(props.artwork),
					creationYear:
						(props.artwork.creation.date.start.value &&
							getArtworkCreationYear(props.artwork)) ||
						null,
					imageId:
						props.artwork.previews && props.artwork.previews.length
							? props.artwork.previews[0].assetId
							: null,
				},
			}).subscribe(
				() => {
					toast.success('Message has been sent');
					closeModal();
					setViewState(ViewState.Ok);
				},
				() => {
					setViewState(ViewState.Ok);
					toast.error('Message sending error');
				},
				() => {
					if (sendMailSubs) {
						sendMailSubs.unsubscribe();
					}
				}
			);
		}
	}

	function buyButtonClick() {
		if (props.artwork.buyLink) {
			if (!props.artwork.buyLink.match(/^https?:\/\//i)) {
				window.open(`https://${props.artwork.buyLink}`, '_blank');
			} else {
				window.open(props.artwork.buyLink, '_blank');
			}
		}
	}

	const inquireButtonDisabled: boolean = Object.keys(fields)
		.map((key: string) => fields[key as InquireModalField].valid)
		.includes(false);

	const creationYear =
		props.artwork.creation &&
			props.artwork.creation.date &&
			props.artwork.creation.date.start.value &&
			props.artwork.creation.date.start.format
			? formatArtworkCreationDate(props.artwork.creation.date, 'YYYY', false)
			: null;

	return (
		<>
			<TextButtonContainer>
				{props.artwork.inquireEmail && (
					<Button
						onClick={() => setModalIsOpen(true)}
						size={ButtonSize.MEDIUM}
						color={Color.PRIMARY}
						disabled={false}
						fullWidth={false}
						type="button">
						Inquire
					</Button>
				)}
				{props.artwork.buyLink && (
					<Button
						onClick={buyButtonClick}
						size={ButtonSize.MEDIUM}
						color={Color.PRIMARY}
						disabled={false}
						fullWidth={false}
						type="button">
						Buy
					</Button>
				)}
			</TextButtonContainer>
			<ReactModal isOpen={modalIsOpen} style={mediumStyles}>
				{viewState === ViewState.Loading ? (
					<div
						style={{
							position: 'absolute',
							background: 'rgba(255,255,255,0.8)',
							width: '100%',
							height: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<img src="/icons/loader.svg" />
					</div>
				) : null}
				<ModalContainer>
					<div id="left-column">
						<StaticArtworkSnapshotBox
							imageSrc={getArtworkImageSrc(
								props.artwork.previews,
								getImageAssetUrl
							)}
							description={{
								artist:
									(props.artwork.authors &&
										props.artwork.authors[0].name.full) ||
									'',
								title:
									(props.artwork.titles && props.artwork.titles[0].value) || '',
								...(creationYear && { creationYear: creationYear }),
							}}
						/>
					</div>
					<div id="right-column">
						<ColumnHeader>
							<h1>Inquire</h1>
							<CloseButton onClick={closeModal}>
								<img src={CloseIcon} alt="Close icon" />
							</CloseButton>
						</ColumnHeader>
						<InputWrapper>
							<Input>
								<InputContainer>
									<LabeledInput
										label="Name"
										name="name"
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											updateFields(InquireModalField.name, {
												value: event.target.value,
												valid: true,
												errorMessage: null,
												disabled: false,
											});
										}}
										value={fields[InquireModalField.name].value}
										background={Background.DARK}
									/>
								</InputContainer>
								<div style={{ flex: 1 }}>
									<LabeledInput
										label="Phone"
										name="phone"
										onChange={(event: ChangeEvent<HTMLInputElement>) => {
											updateFields(InquireModalField.phone, {
												value: event.target.value,
												valid: true,
												errorMessage: null,
												disabled: false,
											});
										}}
										value={fields[InquireModalField.phone].value}
										background={Background.DARK}
									/>
								</div>
							</Input>
							<div style={{ paddingBottom: '2rem' }}>
								<LabeledInput
									label="Email*"
									name="email"
									onChange={(event: ChangeEvent<HTMLInputElement>) => {
										const valid: boolean = isEmailValid(event.target.value);
										const errorMessage: string | null = valid
											? null
											: 'Invalid email';

										updateFields(InquireModalField.email, {
											value: event.target.value,
											valid,
											errorMessage,
											disabled: false,
										});
									}}
									value={fields[InquireModalField.email].value}
									background={Background.DARK}
									error={!fields[InquireModalField.email].valid}
									errorMessage={fields[InquireModalField.email].errorMessage}
								/>
							</div>
							<LabeledTextarea
								label="Message*"
								name="message"
								onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
									const valid: boolean = event.target.value !== '';
									const errorMessage: string | null = valid
										? null
										: 'Field is required';

									updateFields(InquireModalField.message, {
										value: event.target.value,
										valid,
										errorMessage,
										disabled: false,
									});
								}}
								rows={5}
								value={fields[InquireModalField.message].value}
								background={Background.DARK}
								resize={Resize.NONE}
								error={!fields[InquireModalField.message].valid}
								errorMessage={
									fields[InquireModalField.message].errorMessage || undefined
								}
							/>
							<ButtonContainer>
								<Button
									onClick={onSubmit}
									size={ButtonSize.MEDIUM}
									color={Color.PRIMARY}
									disabled={inquireButtonDisabled}
									fullWidth={false}
									type="button">
									Inquire
								</Button>
							</ButtonContainer>
						</InputWrapper>
					</div>
				</ModalContainer>
			</ReactModal>
		</>
	);
}
