import styled from 'styled-components';
import {
	AlignItems,
	BoxSizing,
	Cursor,
	Display,
	FlexDirection,
	FontWeight,
	JustifyContent,
	TextAlign,
} from '../../../../../ads-components/models';
import { labelColor, mutedColor } from '../../../../../styles';
import Breakpoints from '../../../../../styles/Breakpoints';
import { defaultStyle } from '../../../styles';

export const componentStyle = {
	...defaultStyle,
	infoText: {
		...defaultStyle.infoText,
		lineHeight: '23px',
		fontSize: '18px',
		color: '#010304',
	},
	inputLabel: {
		fontSize: '14px',
		lineHeight: '14px',
		marginBottom: '0.2rem',
		color: labelColor,
	},
	inputWrapper: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		width: '100%',
	},
	input: {
		width: '100%',
		height: '2.5rem',
		fontSize: '2.5rem',
		border: 'solid 2px #E0E0E3',
	},
	submitButton: {
		backgroundColor: '#39BBF2',
		color: 'white',
		fontSize: '14px',
		lineHeight: '21px',
		fontWeight: FontWeight.Bold,
		padding: '1rem',
		boxShadow: '0 0 5px 0 rgba(20,75,99,0.17)',
		boxSizing: BoxSizing.BorderBox,
		width: '97px',
		height: '40px',
		textAlign: TextAlign.Center,
		borderRadius: '25px',
		cursor: Cursor.Pointer,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	error: {
		color: '#BD4158',
		fontSize: '13px',
		lineHeight: '16px',
	},
};


const SubmitButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 1.4rem 0 1.5rem 0;

	div{
		&:hover{
			background: #26b4f1 !important;
			box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%) !important;
			transition: all .2s;
		}
	}
`;

const InputContainer = styled.div`
	margin-bottom: 1.6rem;
`;

const RightsContainer = styled.div`
	color: ${mutedColor};
	font-size: 14px;

	@media all and (max-width: ${Breakpoints.phone}) {
		position: absolute;
		bottom: 1.6rem;
 }
`;

const LogoImage = styled.div`
	cursor: pointer;
`;

const ErrorInfo = styled.div`
	margin: -1rem 0 1rem;
`;

const PrivateLoginContainer = styled.div`
	@media all and (max-width: ${Breakpoints.phone}) {
		background: #fff !important;
		justify-content: unset !important;
		margin-top: 6rem;
		overflow: hidden;
	}
`;

const InfoText = styled.div`
	@media all and (max-width: ${Breakpoints.phone}) {
		font-weight: bold;
		font-size: 2rem !important;
		margin-bottom: 2.4rem !important;
	}
`;

const InfoBoxContainer = styled.div`
	@media all and (max-width: ${Breakpoints.phone}) {
		box-shadow: none !important;
		padding: 1.4rem 5rem !important;
		margin: 0 !important;
		height: auto !important;
	}
`;
export {
	SubmitButtonContainer,
	InputContainer,
	RightsContainer,
	LogoImage,
	ErrorInfo,
	PrivateLoginContainer,
	InfoText,
	InfoBoxContainer
}
