import React, { useContext, useEffect, useState } from 'react';
import { ViewState } from '../../models';
import { Album, Artwork } from '../../services/models/api-models';
import { Subscription } from 'rxjs';
import { AppContext } from '../../context/context';
import { useHistory, useParams } from 'react-router-dom';
import { LogoIndicator } from '../../ads-components/indicators/logo-loading-indicator/LogoLoadingIndicator';
import { defaultLogoIndicatorSize } from '../../styles';
import { Effect } from '../../ads-components/indicators/logo-loading-indicator/models';
import { getAlbumView } from '../home/utils';


export function PublicHome(): JSX.Element {
	const componentSubs: Subscription = new Subscription();
	const context = useContext(AppContext);
	const [viewState, setViewState] = useState<ViewState>(ViewState.Loading);
	const [album, setAlbum] = useState<Album<Artwork> | null>(null);
	const params = useParams<{uuid: string | undefined}>();
	const history = useHistory();

	function getPublicAlbum() {
		if(params.uuid) {
			setViewState(ViewState.Loading);
			componentSubs.add(
				context.showroomService.getPublicShowroomObs(params.uuid).subscribe(
					(foundAlbum) => {
						setAlbum(foundAlbum);
						setViewState(ViewState.Ok);
					},
					() => {
						history.push("/public/404");
					}
				)
			)
		} else {
			setViewState(ViewState.Error);
		}
	}

	function loadMoreArtworks(){}

	useEffect(() => () => componentSubs.unsubscribe(), []);
	useEffect(() => {
		getPublicAlbum();
	}, [params.uuid])

	return (
		<>
			{(viewState === ViewState.Loading
			|| viewState === ViewState.Error) && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						flex: '1',
					}}>
					<LogoIndicator
						width={defaultLogoIndicatorSize}
						effect={
							viewState === ViewState.Loading
								? Effect.Pulse
								: Effect.Static
						}>
						{viewState}
					</LogoIndicator>
				</div>
			)}
			{viewState === ViewState.Ok && (
				getAlbumView(
					context.viewportType,
					album,
					loadMoreArtworks,
					false,
				)
			)}
		</>
	);
}
