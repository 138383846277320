import {
	Display,
	FlexDirection,
	JustifyContent,
	WebkitBoxOrient,
	AlignItems,
	BoxSizing,
} from '../../../../../ads-components/models';
import { ComponentStyle as ComponentStyleModel, HeaderStyle } from '../models';
import { defaultText, defaultTitle } from '../../../../../styles';
import styled from 'styled-components';
import Breakpoints from '../../../../../styles/Breakpoints';

export class ComponentStyle implements ComponentStyleModel {
	header: HeaderStyle;

	constructor(headerContainerHeight: string) {
		this.header = {
			container: new Container(headerContainerHeight),
			wrapperContainer,
			wrapperContainer2,
			leftContainer,
			leftTitle,
			leftDescription,
			leftContainerNoImage,
		};
	}
}

class Container {
	height: string;

	display = Display.Flex;
	justifyContent = JustifyContent.SpaceBetween;
	width = '100%';

	constructor(height: string) {
		this.height = height;
	}
}

const wrapperContainer = {
	margin: '4vh 4vw 4.7vh 4vw',
	overflow: 'hidden',
};

const wrapperContainer2 = {
	margin: '4vh 4vw 10vh 0',
	overflow: 'hidden',
};

const leftContainer = {
	display: Display.Flex,
	flexDirection: FlexDirection.Column,
	boxSizing: BoxSizing.BorderBox,
	padding: '0 12vw 2vh 0',
	width: 'auto',
	justifyContent: 'flex-end',
};

const leftContainerNoImage = {};

const leftTitle = {
	margin: '1rem 0',
	display: '-webkit-box',
	WebkitBoxOrient: WebkitBoxOrient.Vertical,
	...defaultTitle,
};

const leftDescription = {
	...defaultText,
};

const CloseButton = styled.div`
	position: absolute;
	right: 0;
	padding: 4vh;
`;

const ArtworkContentContainer = styled.div`
	padding: 10vh 20vw;
	display: flex;
	box-sizing: border-box;
	heightL 100%;
	justify-content: center;
`;

const ArtworkContent = styled.div`
	width: 55vw;
	height: 100%;
	box-sizing: border-box;
	overflow: auto;
`;

const SquareImageContainer = styled.div`
	min-width: 25vh;
	margin-right: 4vw;
`;

const StyledSeparator = styled.div`
	color: #d1d2d2;
	padding: 0 1.8rem;
`;

const NoArtworksContainer = styled.div`
	border-top: 1px solid #e9ebe9;
	padding: 3.1rem 0 0 3.5rem;
	top: 115%;
	position: absolute;
	width: 100%;
`;

const NoArtworksContainerWithImage = styled.div`
	border-top: 1px solid #e9ebe9;
	padding: 3.1rem 0 0 3.5rem;
`;

const NoArtworkInfo = styled.span`
	color: #959699;
	font-style: italic;
	font-size: 1.6rem;
`;

const HomeWrapper = styled.div`
	overflow: auto;
	@media all and (min-width: ${Breakpoints.tablet}) {
		:before {
			display: inline-block;
			content: '';
			width: 100%;
			height: 0.5rem;
			background: #fff;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
`;

export {
	CloseButton,
	ArtworkContentContainer,
	ArtworkContent,
	SquareImageContainer,
	StyledSeparator,
	NoArtworksContainer,
	NoArtworkInfo,
	NoArtworksContainerWithImage,
	HomeWrapper,
};
