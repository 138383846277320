import { FontFamily } from '../ads-components/models';

const defaultTitle = {
	fontFamily: FontFamily.Lato,
	fontWeight: 300,
	fontSize: '4.2rem',
	lineHeight: '125%',
};

const defaultText = {
	fontFamily: FontFamily.Lato,
	fontWeight: 300,
	fontSize: '1.6rem',
	lineHeight: '145%',
};

const defaultBackgroundColor = '#f9f9f9';

const defaultHorizontalMargin = '3vw';

const defaultVerticalMargin = '4rem';

const defaultLogoIndicatorSize = '8rem';

const textColor = '#050E0F';

const labelColor = '#4A4C4D';

const mutedColor = '#7F8283';

const blueColor = '#0084BB';

export {
	defaultTitle,
	defaultText,
	defaultHorizontalMargin,
	defaultVerticalMargin,
	defaultBackgroundColor,
	defaultLogoIndicatorSize,
	textColor,
	labelColor,
	mutedColor,
	blueColor,
};
