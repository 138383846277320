// @ts-ignore
import crypto from 'crypto-browserify';
import {
	EncryptedAlbum,
	Album,
	EncryptedArtwork,
	Artwork,
} from '../../../services/models/api-models';

// NOTE: Used to decrypt private album data and private artwork data
function decrypt(encryptedString: string, password: string): string {
	// NOTE: Previous album logic copy
	//       https://gitlab.arteia.xyz/frontend/public-albums-client/-/blob/staging/src/scenes/PrivateRoom/index.js
	if (!encryptedString) {
		throw 'Empty encrypted string';
	}

	const passwordHash = crypto
		.createHash('md5')
		.update(password, 'utf-8')
		.digest('hex')
		.toUpperCase();
	const ivBuffer = Buffer.from('5ebe2294ecd0e0f08eab7690d2a6ee69', 'hex');
	const decipher = crypto.createDecipheriv(
		'aes-256-ctr',
		passwordHash,
		ivBuffer
	);
	let dec = decipher.update(encryptedString, 'hex', 'utf8');
	dec += decipher.final('utf8');

	return dec;
}

export function decryptAlbum(
	album: EncryptedAlbum,
	password: string
): Album<EncryptedArtwork> | null {
	try {
		const data = JSON.parse(decrypt(album.data, password));

		return {
			_id: album._id,
			uuid: album.uuid,
			expirationDate: album.expirationDate,
			artworks: album.artworks,
			artworksCount: album.artworksCount,
			...data,
		};
	} catch {
		return null;
	}
}

export function decryptArtwork(
	artwork: EncryptedArtwork,
	password: string
): Artwork | null {
	try {
		const data = JSON.parse(decrypt(artwork.data, password));

		return {
			_id: artwork._id,
			artworkOffset: artwork.artworkOffset,
			albumUuid: artwork.albumUuid,
			expirationDate: artwork.expirationDate,
			...data,
		};
	} catch {
		return null;
	}
}

export function decryptImage(assetId: string, password: string): string | null {
	try {
		return decrypt(assetId, password);
	} catch {
		return null;
	}
}
