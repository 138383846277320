import { Display, AlignItems, Cursor } from '../../../ads-components/models';
import { textColor } from '../../../styles';

export const actionStyle = {
	navigationButton: {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		cursor: Cursor.Pointer,
		padding: '0 1.2rem',
		borderLeft: '1px solid #E9EBE9',
	},

	arrow: {
		marginRight: '0.8rem',
		marginLeft: '1rem'
	},

	text: {
		fontSize: '1.4rem',
		marginLeft: '0.5rem',
		color: textColor,
	},
};
