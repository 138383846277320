import React from 'react';
import { Measurement } from '../../../../ads-components/boxes/static-image-box/models';
import { RowHorizontalContainer } from '../../../../ads-components/containers/grids/rows/RowHorizontalContainer';
import { AppContext } from '../../../../context/context';
import { Props, State } from '../../models';
import {
	getInitialState,
	updateDetailsState,
	cropAlbumName,
} from '../../utils/';
import { ArtworkDetails } from '../generic/artwork-details/ArtworkDetails';
import { ArtworkMeasurements } from '../generic/artwork-measurements/ArtworkMeasurements';
import { NavigationBar } from '../generic/navigation-bar/NavigationBar';
import { componentStyle as cs } from './styles';
import { ViewActionsPortal } from '../../../generic/view-actions-portal/ViewActionsPortal';
import { actionStyle } from '../../../top-bar/styles';
import { ActionsContainer } from '../../../home/views/small-portrait/styles';
import { SalesDetails } from '../generic/sales-details';
import { ArtworkDetailsTabs, createArtworkDetailsTabs } from '../generic/artworks-details-tabs';
import Viewer from 'viewerjs';
import { getImageAssetUrl } from '../../../../utils';

export class DetailsSmallPortrait extends React.Component<Props, State> {
	// NOTE: How to establish context model in ts?
	static contextType = AppContext;
	currentContext: string | null = null;

	previewsContainerRef = React.createRef<HTMLDivElement>();

	viewer: Viewer | null = null;

	constructor(props: Props) {
		super(props);

		this.state = getInitialState();
	}

	componentDidMount() {
		this.currentContext = JSON.stringify(this.context);

		updateDetailsState.call(this, cs, cs.previewList.height, Measurement.Width);

		const element = document.getElementById("viewerjs-previews");

		if (!element) {
			throw Error('Unable to find viewerjs previews element');
		}

		this.viewer = new Viewer(element!, {
			inline: false,
			backdrop: false,
		});
	}

	componentDidUpdate(prevProps: Props, prevState: State) {
		if (
			prevProps.previewIndex !== this.props.previewIndex ||
			prevProps.artwork._id !== this.props.artwork._id
		) {
			updateDetailsState.call(
				this,
				cs,
				cs.previewList.height,
				Measurement.Width
			);
		}

		const element = document.getElementById('viewerjs-previews');

		if (element) {
			if (this.viewer !== null) {
				this.viewer.destroy();
				this.viewer = new Viewer(element!, {
					inline: false,
					backdrop: false,
				});
			}
		}

		if (this.viewer) {
			this.viewer.destroy();
			this.viewer.update();
			this.viewer.moveTo(0);
		}
	}

	openImageViewer() {
		if (this.viewer) {
			this.viewer.show();
		}
	}


	render() {
		return (
			<div style={cs.wrapper} id="wrapper">
				<div style={cs.navigationBar}>
					<NavigationBar
						albumArtworkCount={this.props.albumArtworkCount}
						artworkIndex={this.props.artwork.artworkOffset}
						changeArtwork={this.props.changeArtworkFn}
					/>
				</div>

				<div style={cs.preview} id="preview">
					<div style={{
						...cs.imageSmall,
						cursor: this.state.previews ? 'zoom-in' : '',
						pointerEvents: this.state.previews ? 'all' : 'none',
					}}
						id="image"
						onClick={() => this.openImageViewer()}>
						{this.state.preview}
					</div>
					<div id="previews-container" style={cs.previewList}>
						<RowHorizontalContainer
							numberOfRows={1}
							rowHeight={cs.previewList.height}>
							{this.state.previews}
						</RowHorizontalContainer>
					</div>
				</div>

				<ul
					id="viewerjs-previews"
					style={{ height: "0px", width: "0px", overflow: "hidden" }}
				>
					{(this.props.artwork.previews || []).map((preview) => (
						<li>
							<img src={getImageAssetUrl(preview.assetId)} />
						</li>
					))}
				</ul>

				<div id="content" style={cs.content}>
					<ArtworkDetails
						artwork={this.props.artwork}
					/>
					<SalesDetails artwork={this.props.artwork} />
					<ArtworkDetailsTabs artwork={this.props.artwork} />
				</div>

				<ViewActionsPortal>
					<ActionsContainer>
						<div
							onClick={() => {
								if (this.props.private) {
									this.props.history.push(`/private/${this.props.artwork.albumUuid}/artworks`,
										{ ...this.props.location.state });
								} else {
									this.props.history.push(`/public/${this.props.artwork.albumUuid}/artworks`);
								}
							}
							}
							style={actionStyle.navigationButton}>
							<img
								style={actionStyle.arrow}
								src="/icons/ico-showroom-arrow-left.svg"
							/>
							<span className='action_tablet' style={{ fontSize: 'smaller' }}>
								{cropAlbumName(this.props.albumName, 23)}
							</span>
							<span className="action_mobile" style={{}}>Back</span>

						</div>
					</ActionsContainer>
				</ViewActionsPortal>
			</div>
		);
	}
}
