export enum AlignItems {
	Start = 'start',
	FlexStart = 'flex-start',
	Center = 'center',
	End = 'end',
	FlexEnd = 'flex-end',
	SelfStart = 'self-start',
	SelfEnd = 'self-end',
	Stretch = 'stretch',
	Baseline = 'baseline',
}
