import React from 'react';
import { Props } from './models';
import { ArrowContainer, componentStyle as cs } from './styles';
import { mutedColor, textColor } from '../../../../../styles';

export function NavigationBar(props: Props) {
	return (
		<div style={cs.wrapper}>
			<ArrowContainer
				onClick={() => props.changeArtwork('previous')}
			>
				<img
					style={cs.arrow(!!props.artworkIndex)}
					src="/icons/ico-showroom-arrow-left.svg"
				/>
			</ArrowContainer>
			<span style={cs.numberDisplay}>
				<strong style={{ paddingRight: '.5rem', color: textColor }}>
					{props.artworkIndex + 1}
				</strong>
				<span style={{ paddingRight: '.5rem', color: mutedColor }}>/</span>
				<span style={{ color: mutedColor }}>{props.albumArtworkCount}</span>
			</span>
			<ArrowContainer
				onClick={() => props.changeArtwork('next')}
			>
				<img
					style={cs.arrow(props.albumArtworkCount - 1 > props.artworkIndex)}
					src="/icons/ico-showroom-arrow-right.svg"
				/>
			</ArrowContainer>
		</div>
	);
}
