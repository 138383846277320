import { ImperialUnitLabelEn, MetricUnitLabelEn } from '../models';
import { round } from '../../../utils';
import { Labels } from '../../../models/globals';

const CentimeterToIn: number = 0.393700981;

export function convertMetricDimensionToImperial(
	value: string | number,
	unit: {
		id: string,
		labels: Labels,
	},
	resultUnit: ImperialUnitLabelEn,
	roundPlaces: number = 2,
): string | null {
	if (resultUnit === ImperialUnitLabelEn.in) {
			switch (unit.labels.en) {
				case MetricUnitLabelEn.mm:
				case MetricUnitLabelEn.millimeter:
					return `${round(0.1 * +value * CentimeterToIn, roundPlaces)}`;
				// NOTE: centimeter is frequent used label.en in Demo collection (mostly in old artworks)
				case MetricUnitLabelEn.cm:
				case MetricUnitLabelEn.centimeter:
					return `${round(+value * CentimeterToIn, roundPlaces)}`;
				case MetricUnitLabelEn.dm:
				case MetricUnitLabelEn.decimeter:
					return `${round(10 * +value * CentimeterToIn, roundPlaces)}`;
				case MetricUnitLabelEn.m:
				case MetricUnitLabelEn.meter:
					return `${round(100 * +value * CentimeterToIn, roundPlaces)}`;
				default:
					console.warn('Cannot convert given unit', unit);
					return null;
			}
	}
	console.warn('Not implemented imperial unit', resultUnit);
	return null;
}
