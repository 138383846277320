import styled from 'styled-components';
import {
	AlignItems,
	BoxSizing,
	Display,
	FlexDirection,
	FontWeight,
	JustifyContent,
	WhiteSpace,
} from '../../../../../ads-components/models';
import {
	blueColor,
	defaultText,
	defaultTitle,
	defaultVerticalMargin,
	textColor,
} from '../../../../../styles';
import Breakpoints from '../../../../../styles/Breakpoints';
import { ComponentStyle as ComponentStyleModel } from '../models';

export class ComponentStyle implements ComponentStyleModel {
	container = {
		display: Display.Flex,
		height: '100%',
		overflow: 'hidden',
		flexDirection: FlexDirection.Column,
	};

	tabsContainer = {
		display: Display.Flex,
		height: '4.8rem',
		boxShadow: 'rgba(0,0,0,0.5) 0 3px 4px -2px',
		zIndex: 1,
	};

	tab = (active: boolean) => new TabStyle(active);

	header: any;

	contentContainer = {
		overflow: 'auto',
		height: '100%',
		boxSizing: BoxSizing.BorderBox,
	};

	title = {
		...defaultTitle,
		margin: '1rem 0',
	};

	description = {
		marginBottom: defaultVerticalMargin,
		...defaultText,
		fontSize: '1.6rem',
	};

	iconStatusBarContainer = {
		display: Display.Flex,
	};

	artworkList = {
		height: '100%',
	};

	constructor(headerContainerHeight: string) {
		this.header = new Header(headerContainerHeight);
	}
}

class Header {
	height: string;
	width = '100%';

	constructor(height: string) {
		this.height = height;
	}
}

export class TabStyle {
	display = Display.Flex;
	alignItems = AlignItems.Center;
	justifyContent = JustifyContent.SpaceAround;
	boxSizing = BoxSizing.BorderBox;
	flex = '1 1 auto';
	padding = '2rem';
	cursor = 'pointer';
	color = blueColor;
	fontWeight = FontWeight.Bold;

	constructor(active: boolean) {
		this.color = active ? blueColor : textColor;
	}
}

const DetailsImageContainer = styled.div`
	margin: 2rem 0;

	@media all and (max-width: ${Breakpoints.phone}) {
		margin: 0;
	}
`;

const ActionsContainer = styled.div`
	@media all and (min-width: ${Breakpoints.phone}) {
		.action_mobile {
			display: none;
		}
	}

	@media all and (max-width: 766px) {
		.action_tablet {
			display: none;
		}
	}
`;

const IndicatorsContainer = styled.div`
	display: flex;
	margin-top: 1rem;
	@media all and (max-width: ${Breakpoints.tablet}) {
		margin-top: 0;
	}
`;

const NoArtworkInfo = styled.div`
	color: #959699;
	font-size: 1.6rem;
	font-style: italic;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TitleContainer = styled.h1`
	@media all and (max-width: ${Breakpoints.tablet}) {
		font-size: 4rem !important;
		font-weight: 300 !important;
	}

	@media all and (max-width: 766px) {
		font-size: 2.5rem !important;
		font-weight: bold !important;
	}
`;

const DetailsContent = styled.div`
	overflow: auto;
	@media all and (max-width: ${Breakpoints.tablet}) {
		padding: 2rem 3vw 0;
	}

	@media all and (max-width: 766px) {
		padding: 2rem 4vw 0;
	}
`;

const DetailsContentTablet = styled.div`
	@media all and (max-width: 766px) {
		display: none;
	}
`;

const DetailsContentPhone = styled.div`
	@media all and (min-width: ${Breakpoints.phone}) {
		display: none;
	}
`;

const DescriptionContainer = styled.div`
	@media all and (max-width: ${Breakpoints.tablet}) {
		padding: 0 3vw;
	}

	@media all and (max-width: ${Breakpoints.phone}) {
		padding: 0 4vw;
	}
`;

export {
	DetailsImageContainer,
	ActionsContainer,
	IndicatorsContainer,
	NoArtworkInfo,
	TitleContainer,
	DetailsContent,
	DetailsContentTablet,
	DetailsContentPhone,
	DescriptionContainer,
};
