import React from 'react';
import { Props } from './model';
import { StyledLoader } from './styles';

export const Loader = (props: Props) => (
	<div style={{ width: '100%', height: '100%', backgroundColor: '#F2F2F2' }}>
		<StyledLoader {...props}>
			<img src="/icons/loader.svg" />
		</StyledLoader>
	</div>
);

Loader.defaultProps = {
	size: 'default',
};
