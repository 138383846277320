// NOTE: enum contains all covered units in metric units system
// NOTE: full name of units came from era of custom units created in c-1
// NOTE: new created dimensions will have unit.labels.en equal to one of below abbreviation unit name
export enum MetricUnitLabelEn {
	mm = 'mm',
	millimeter = 'millimeter',
	cm = 'cm',
	centimeter = 'centimeter',
	dm = 'dm',
	decimeter = 'decimeter',
	m = 'm',
	meter ='meter',
}
