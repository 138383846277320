export function scrollToPosition(
	targetId: string,
	positionX: number,
	positionY: number
): void {
	requestAnimationFrame(() => {
		const scrollTarget = document.getElementById(targetId);

		scrollTarget && scrollTarget.scrollTo(positionX, positionY);
	});
}
