import * as React from 'react';
import { Props } from './models';
import { componentStyle as cs } from './styles';

export function ArtworkDescriptionBar(props: Props) {
	return (
		<div style={cs.wrapper}>
			<div style={cs.artist}>{props.artist}</div>
			<div style={{ display: 'flex' }}>
				<div>
					<h1 style={cs.title}>{props.title}</h1>
				</div>
				{props.creationYear && (
					<div style={cs.creation}><span style={{ marginRight: '0.2rem' }}>{props.creationYear ? ',' : null}</span>{props.creationYear}</div>
				)}
			</div>
			{props.inventoryNumber && (
				<div style={cs.inventoryNumber}>{props.inventoryNumber}</div>
			)}
		</div>
	);
}
