import { Labels } from '../../../models/globals';
import { ImperialUnitLabelEn, MetricUnitLabelEn } from '../models';
import { convertImperialDimensionToMetric } from './convert-imperial-dimension-to-metric';
import { convertMetricDimensionToImperial } from './convert-metric-dimension-to-imperial';

export function convertToOtherSystem(
	value: string | number,
	unit: {
		id: string,
		labels: Labels,
	},
	conversionUnit: MetricUnitLabelEn | ImperialUnitLabelEn
): string | null {
	// @ts-ignore
	if(Object.values(MetricUnitLabelEn).includes(conversionUnit)){
		// NOTE: conversion to metric units system
		return convertImperialDimensionToMetric(value, unit, conversionUnit as MetricUnitLabelEn);
	} else {
		// NOTE: conversion to imperial units system
		return convertMetricDimensionToImperial(value, unit, conversionUnit as ImperialUnitLabelEn);
	}
}
