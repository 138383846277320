export enum Overflow {
	Auto = 'auto',
	MozInitial = '-moz-initial',
	Inherit = 'inherit',
	Initial = 'initial',
	Revert = 'revert',
	Unset = 'unset',
	Hidden = 'hidden',
	Scroll = 'scroll',
	Visible = 'visible',
}
