import AES from 'crypto-js/aes';
import { enc } from 'crypto-js';
import WordArray from 'crypto-js'

export function encodePassword(password: string):string {
	if(process.env.REACT_APP_SHOWROOM_PASS_SECRET_KEY
		&& process.env.REACT_APP_SHOWROOM_PASS_IV
	) {
		const parsedKey: WordArray.lib.WordArray = enc.Base64.parse(process.env.REACT_APP_SHOWROOM_PASS_SECRET_KEY);
		const iv: WordArray.lib.WordArray = enc.Base64.parse(process.env.REACT_APP_SHOWROOM_PASS_IV);
		// NOTE: that iv is required without it the same password with the same key give different results
		const cipherData: WordArray.lib.CipherParams = AES.encrypt(password, parsedKey, { iv });
		return cipherData.toString();
	}
	throw new Error("Cannot load secret key")
}

