import {
	Display,
	FlexDirection,
	JustifyContent,
	BoxSizing,
	Cursor,
	AlignItems,
} from '../../../../../ads-components/models';
import { defaultBackgroundColor } from '../../../../../styles';

const defaultOutlineColor = '#0089D4';
const defaultOutlineOffset = '3px';

export const componentStyle = {
	wrapper: {
		overflow: 'auto',
		height: '100%',
	},
	navigationBar: {
		margin: '2vh 0',
	},
	image: {
		display: Display.Flex,
		justifyContent: JustifyContent.Start,
		alignItems: AlignItems.Start,
		// NOTE: Height needs to be set because we want to have previews slider in the same place regardless of preview height
		height: '60vh',
		width: '100vw',
		overflow: 'auto',
	},
	imageSmall: {
		display: Display.Flex,
		justifyContent: JustifyContent.Start,
		alignItems: AlignItems.Start,
		// NOTE: Height needs to be set because we want to have previews slider in the same place regardless of preview height
		width: '100vw',
		overflow: 'hidden',
	},
	preview: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		boxSizing: BoxSizing.BorderBox,
		width: '100%',
		background: defaultBackgroundColor,
	},
	previewList: {
		height: '23vw',
		boxSizing: BoxSizing.BorderBox,
		margin: '4vw 0 4vw 4vw',
	},
	previewBox: function (isSelected: boolean) {
		let opacity = '1';
		let outline = 'none';
		let outlineOffset = '0';
		let cursor = Cursor.Pointer;
		let minWidth: 'auto';

		if (!isSelected) {
			opacity = '.7';
		} else {
			outline = `${defaultOutlineOffset} solid ${defaultOutlineColor}`;
			outlineOffset = `-${defaultOutlineOffset}`;
			cursor = Cursor.Default;
			minWidth = 'auto';
		}

		return {
			marginRight: '2vw',
			opacity,
			outline,
			outlineOffset,
			cursor,
			minWidth: 'auto',
		};
	},
	content: {
		margin: '2rem 3vw',
		wordWrap: 'break-word',
	} as React.CSSProperties,
};
