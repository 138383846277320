import * as React from 'react';
import { Props } from '../models';
import { ComponentStyle, ImageContainer, ImageWrapper } from './styles';

export function LogoToolbar(props: React.PropsWithChildren<Props>) {
	const cs = new ComponentStyle();

	return (
		<ImageWrapper style={cs.wrapper}>
			<ImageContainer>
				<img
					onClick={() => props.onLogoClick && props.onLogoClick()}
					src={props.imageUrl}
					alt="logo"
					style={cs.image}
				/>
			</ImageContainer>
			{props.children}
		</ImageWrapper>
	);
}
