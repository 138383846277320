import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { arteiaLogoShowroomsSrc } from '../generic';
import { componentStyle as cs, ButtonText, TextButtonContainer, LoginWrapper, InfoBoxLogin, InfoText, SubmitButton } from './styles';
import { ViewState } from '../../models';
import { Props } from './models';
import { AppContext } from '../../context/context';
import { ErrorInfo, InputContainer, LogoImage, RightsContainer, SubmitButtonContainer } from '../private/components/login/styles';
import { LabeledInput, TextButton } from 'ads';
import { Background, ButtonSize, Color, Size } from 'ads/dist/src/components/models';

export function Login(props: Props) {
	const context = useContext(AppContext);

	const [login, setLogin] = useState('test@test.com');
	const [password, setPassword] = useState('12345678');
	const [viewState, setViewState] = useState(ViewState.Ok);
	const currentYear = new Date().getFullYear()
	let history = useHistory();

	function handleSubmit(email: string, password: string) {
		setViewState(ViewState.Loading);

		const subscription = context.authService.login(email, password).subscribe(
			(loggedIn) => {
				history.push(props.redirectTo || '/');

				subscription.unsubscribe();
			},
			(loggedIn) => {
				setViewState(ViewState.Error);

				subscription.unsubscribe();
			}
		);
	}

	return (
		<LoginWrapper style={cs.wrapper}>
			<LogoImage>
				<img src={arteiaLogoShowroomsSrc} onClick={() => history.push('/')} />
			</LogoImage>
			<InfoBoxLogin style={cs.infoBoxLogin}>
				<div style={{ marginBottom: '-1.5rem' }}>
					<InfoText style={cs.infoText}>
						<span>
							Login to your account
						</span>
					</InfoText>
					<InputContainer>
						<LabeledInput
							label='Email'
							name="email"
							onChange={(event) => {
								setLogin(event.target.value);
							}}
							//@ts-ignore
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									handleSubmit(login, password);
								}
							}}
							error={viewState === ViewState.Error ? true : false}
							errorMessage=''
							value={login}
							background={Background.DARK}
							size={Size.DEFAULT}
						/>
					</InputContainer>
					<InputContainer>
						<LabeledInput
							label='Password'
							name="password"
							onChange={(event) => {
								setPassword(event.target.value);
							}}
							//@ts-ignore
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									handleSubmit(login, password);
								}
							}}
							error={viewState === ViewState.Error ? true : false}
							errorMessage=''
							value={password}
							background={Background.DARK}
							size={Size.DEFAULT}
						/>
					</InputContainer>
					{viewState === ViewState.Error ? (
						<ErrorInfo>
							<span style={cs.error}>
								Incorrect credentials. Please verify login and password.
							</span>
						</ErrorInfo>
					) : null}
				</div>
				<SubmitButtonContainer>
					<SubmitButton
						style={cs.submitButton}
						onClick={() => handleSubmit(login, password)}>
						<span>Log in</span>
					</SubmitButton>
				</SubmitButtonContainer>
				<TextButtonContainer>
					<TextButton
						onClick={() => window.open('https://secure.arteia.com/forgot-password')}
						size={ButtonSize.SMALL}
						color={Color.DEFAULT}
						disabled={false}
						fullWidth={false}
						type="submit"
					>
						<ButtonText>
							Forgot password?
						</ButtonText>
					</TextButton>
					<div style={{ marginBottom: '1.2rem' }}></div>
					<TextButton
						onClick={() => window.open('https://secure.arteia.com/sign-up')}
						size={ButtonSize.SMALL}
						color={Color.DEFAULT}
						disabled={false}
						fullWidth={false}
						type="submit"
					>
						<ButtonText>
							Don’t have an account?
						</ButtonText>
					</TextButton>
				</TextButtonContainer>
			</InfoBoxLogin>
			<RightsContainer>
				<span>
					@ {currentYear} Arteïa - All rights reserved
				</span>
			</RightsContainer>
		</LoginWrapper>
	);
}
