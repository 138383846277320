import styled from 'styled-components';
import {
	AlignItems,
	BoxSizing,
	Cursor,
	Display,
	FlexDirection,
	FontStyle,
	FontWeight,
	JustifyContent,
	Position,
	FontFamily,
} from '../../../../../../ads-components/models';
import {
	defaultBackgroundColor,
	defaultTitle,
	textColor,
} from '../../../../../../styles';
import Breakpoints from '../../../../../../styles/Breakpoints';

const middleContainerViewAlbumButton = {
	cursor: Cursor.Pointer,
	marginBottom: '2vh',
};

const coverImageContainer = {
	position: Position.Relative,
	cursor: Cursor.Pointer,
	background: '#f2f2f2',
};

export class ComponentStyle {
	wrapper: Wrapper;
	albumItemsContainer: AlbumItemsContainer;

	middleContainer = {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		flex: '1',
		padding: '6vh',
		//justifyContent: JustifyContent.SpaceEvenly,
		boxSizing: BoxSizing.BorderBox,
	};

	middleContainerInfoBar = {
		display: '-webkit-box',
		justifyContent: 'space-between',
	};

	middleContainerTitle = {
		...defaultTitle,
		cursor: Cursor.Pointer,
		fontSize: '2.5rem',
		fontFamily: FontFamily.Lato,
		lineHeight: '125%',
		fontWeight: FontWeight.Bolder,
		color: textColor,
	};

	middleContainerBottomBar = {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		justifyContent: JustifyContent.SpaceBetween,
		marginTop: '3rem',
	};

	middleContainerViewAlbumButton = middleContainerViewAlbumButton;

	forSaleTag = {
		position: Position.Absolute,
		margin: '1rem',
		color: '#565EE2',
	};

	coverImageContainer = coverImageContainer;

	noArtworksIndicator = {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		backgroundColor: defaultBackgroundColor,
		color: '#959699',
		fontStyle: FontStyle.Italic,
		width: '34.6vh',
	};

	constructor(
		boxSideSize: string,
		albumItemsSpacing: string,
		albumItemsColumns: number,
		albumItemsRows: number
	) {
		this.wrapper = new Wrapper(boxSideSize);
		this.albumItemsContainer = new AlbumItemsContainer(
			albumItemsSpacing,
			albumItemsColumns,
			albumItemsRows
		);
	}
}

class AlbumItemsContainer {
	display = Display.Grid;
	padding: string;
	gridTemplateColumns: string;
	gridTemplateRows = 'repeat(3, 1fr)';
	gridGap: string;
	backgroundColor = defaultBackgroundColor;

	constructor(spacing: string, columns: number, rows: number) {
		this.padding = spacing;
		this.gridGap = `2vh`;
		this.gridTemplateColumns = `repeat(${columns}, 1fr)`;
		this.gridTemplateRows = `repeat(${rows}, 1fr)`;
	}
}

class Wrapper {
	height: string;

	display = Display.Flex;
	width = '100%';
	margin = '1px 0 4vh 0';
	boxShadow = `0 0 4px 0 rgba(0,0,0,0.15)`;
	boxSizing = BoxSizing.BorderBox;

	constructor(boxSideSize: string) {
		this.height = boxSideSize;
	}
}

const AlbumItems = styled.div`
	.square-image-wrapper {
		background: #f2f2f2;
		&:hover{
			background: #ffffff;
			opacity: 0.9;
			transition: 0.2s;
			cursor: pointer;
		}
`;

const ImageContainer = styled.div`
	&:hover {
		transition: 0.2s;
	}
`;

const MiddleContainerTitle = styled.h1`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: ${textColor};
	font-size: 2.5rem;
	font-weight: bold;
	margin: 1rem 0;
	line-height: 125%;

	@media all and (max-width: ${Breakpoints.tablet}) {
		font-size: 2.8rem;
	}

	@media all and (max-width: ${Breakpoints.phone}) {
		font-size: 2rem;
	}
`;

const StyledSeparator = styled.div`
	color: #d1d2d2;
	padding: 0 1.8rem;
`;

const MiddleContainerDescription = styled.div`
	margin: 0.7rem 0;
	font-size: 1.6rem;
`;

const MiddleContainerDescriptionTablet = styled.div`
	font-size: 1.6rem;
`;

export {
	AlbumItems,
	ImageContainer,
	MiddleContainerTitle,
	StyledSeparator,
	MiddleContainerDescription,
	MiddleContainerDescriptionTablet,
};
