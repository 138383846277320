import { EmptyStateIcon } from "../../configs";

export const missingArtworkIndicator = EmptyStateIcon.ARTWORK

export const missingArtworkIndicatorSmall = EmptyStateIcon.ARTWORK_SMALL

export const artworksLoadNumber = 4;

export const arteiaLogoShowroomsSrc = '/images/logo-arteia-showrooms.svg';

export const arteiaLogoSrc = '/images/Logo-arteïa-black@2x.svg';
