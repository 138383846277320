import { BaseStyle } from '../../styles';
import {
	Cursor,
	Display,
	JustifyContent,
	AlignItems,
} from '../../../../models';
import styled from 'styled-components';
import Breakpoints from '../../../../../styles/Breakpoints';

export class ComponentStyle extends BaseStyle {
	wrapper = {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
	};
	image = {
		cursor: Cursor.Pointer,
		width: '9.2rem',
	};
}

const ImageContainer = styled.div`
	@media all and (max-width: ${Breakpoints.tablet}) {
		img {
			width: 6.9rem !important;
		}
	}
	@media all and (max-width: ${Breakpoints.phone}) {
		img {
			width: 6.9rem !important;
		}
	}
`;

const ImageWrapper = styled.div`
	height: 8rem;

	@media all and (max-width: ${Breakpoints.tablet}) {
		height: 6.2rem;
	}

	@media all and (max-width: ${Breakpoints.phone}) {
		height: 5.8rem;
	}
`;

export { ImageContainer, ImageWrapper };
