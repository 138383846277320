import { Month } from './models';

export function getExpirationDate(
	inputDate: string,
	shortenedMonth?: boolean,
): string {
	if (isNaN(Date.parse(new Date(+inputDate).toString()))) {
		console.warn('Unable to parse album expiration date');

		return '';
	}

	const date = new Date(+inputDate);

	const dateUTC = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

	const month: string = shortenedMonth
		? Month[new Date(dateUTC).getMonth()].slice(0,3)
		: Month[new Date(dateUTC).getMonth()]

	return `Expires on ${new Date(dateUTC).getDate()} ${month} ${new Date(dateUTC).getFullYear()}`;
}
