import styled from 'styled-components';
import { labelColor } from '../../../../styles';

export const componentStyle = {
	wrapper: {
		fontSize: '1.4rem',
		color: labelColor,
	},
};


const ExpirationIndicatorContainer = styled.div`
    @media all and (max-width: 320px) {
        font-size: 1.2rem !important;
    }
`;

export {
	ExpirationIndicatorContainer
}