import { Album, Artwork } from '../models/api-models';
import { Showroom, ShowroomArtwork } from '../../models/showroom';
import { mapShowroomArtworkToAlbumArtwork } from './mapShowroomArtworkToAlbumArtwork';

export const mapShowroomToPresentationAlbum = (
	showroom: Showroom
): Album<Artwork> => ({
	_id: showroom.id,
	// TODO: uncomment below line in the future
	//uuid: showroom.statisticsId,
	uuid: showroom.id,
	name: showroom.name,
	cover: showroom.cover
		? {
				assetId: showroom.cover,
				nano: showroom.cover,
				small: showroom.cover,
				hd: showroom.cover,
		  }
		: { assetId: '', nano: '', small: '', hd: '' },
	forSale: !!showroom.globalArtworksConfig.retailPrice,
	description: showroom.description,
	participation: {
		// NOTE: verify usage og that field
		doesParticipate: false,
	},
	expirationDate: showroom.publicationConfig.endDate || '',
	creationDate: showroom.createdAt,
	artworks: showroom.artworks.map(
		(artwork: ShowroomArtwork, index: number): Artwork =>
			mapShowroomArtworkToAlbumArtwork(artwork, showroom, index)
	),
	contactEmail: showroom.globalArtworksConfig.inquireButton
		? showroom.globalArtworksConfig.inquireButton.inquireEmail
		: '',
	timesVisited: 0,
	artworksCount: showroom.artworks.length,
	likes: 0,
});
