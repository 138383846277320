import { Artwork } from '../../../../../../services/models/api-models';
import { Tab } from '../../../../../../ads-components/boxes/tabbed-text-box/models';
import { ArtworkDetailsTab } from '../models';
import {
	createDescriptionTab,
	createExhibitionsTab,
	createLiteratureTab,
	createProvenanceTab,
} from './helpers';

export function createArtworkDetailsTabs(
	artwork: Artwork
): Tab<ArtworkDetailsTab>[] {
	const tabs: (Tab<ArtworkDetailsTab> | null)[] = [];
	tabs.push(createProvenanceTab(artwork));
	tabs.push(createLiteratureTab(artwork));
	tabs.push(createExhibitionsTab(artwork));
	tabs.push(createDescriptionTab(artwork));

	return tabs.filter((tab) => tab !== null) as Tab<ArtworkDetailsTab>[];
}
