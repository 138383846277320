import React, { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { StaticArtworkSnapshotBox } from '../../../../ads-components/boxes/artwork-snapshot-box/static/StaticArtworkSnapshotBox';
import { MaxTextLinesBox } from '../../../../ads-components/boxes/max-text-lines-box/MaxTextLinesBox';
import { SquareImageBox } from '../../../../ads-components/boxes/square-image-box/SquareImageBox';
import { ColumnVerticalContainer } from '../../../../ads-components/containers/grids/columns/ColumnVerticalContainer';
import { AppContext } from '../../../../context/context';
import { ViewportType } from '../../../../context/models';
import { AlbumViewModel } from '../../../../models';
import { defaultHorizontalMargin } from '../../../../styles';
import {
	formatArtworkCreationDate,
	getArtworkImageSrc,
	getImageAssetUrl,
	getPrivateArtworkImageSrc,
} from '../../../../utils';
import {
	ExpirationIndicator,
	IconsStatusBarContainer,
	PostIndicator,
} from '../../../generic';
import { EncryptedSquareImageBox } from '../../../generic/encrypted/encrypted-square-image/EncryptedSquareImage';
import { EncryptedStaticArtworkSnapshotBox } from '../../../generic/encrypted/encrypted-static-artwork-snapshot-box/EncryptedStaticArtworkSnapshotBox';
import { artworkPreviewClick } from '../../utils';
import {
	ActionsContainer,
	ComponentStyle,
	DescriptionContainer,
	DetailsContent,
	DetailsContentPhone,
	DetailsContentTablet,
	DetailsImageContainer,
	IndicatorsContainer,
	NoArtworkInfo,
	TitleContainer,
} from './styles';
import { LoadMoreBar } from '../../../generic/load-more-bar/LoadMoreBar';
import { actionStyle } from '../../../top-bar/styles';
import { ViewActionsPortal } from '../../../generic/view-actions-portal/ViewActionsPortal';
import { StyledSeparator } from '../large-landscape/styles';
import { AlbumItems } from '../../../list/list-item/views/large-landscape/styles';
import { EmptyStateIcon } from '../../../../configs';

export function HomeSmallPortrait(props: AlbumViewModel) {
	const context = useContext(AppContext);
	const [activeTabId, setActiveTabId] = useState(0);

	let history = useHistory();

	const cs = new ComponentStyle(
		context.viewportType === +ViewportType.SmallPortrait ? '50vh' : '70vh'
	);

	const tabScrollPositions = useRef({ details: 0, artworks: 0 });

	const image = props.coverImageSrc === EmptyStateIcon.ARTWORK;

	const detailsElement = (
		<>
			<DetailsContentTablet>
				<DetailsContent>
					<IndicatorsContainer>
						<PostIndicator postedOn={props.postedOn} />
						{props.expiresOn && <StyledSeparator> | </StyledSeparator>}
						<ExpirationIndicator expiresOn={props.expiresOn} />
					</IndicatorsContainer>

					<TitleContainer style={cs.title}>{props.title}</TitleContainer>

					<div style={cs.iconStatusBarContainer}>
						<IconsStatusBarContainer
							albumUuid={props.uuid}
							likes={props.likes}
							timesVisited={props.timesVisited}
							artworksCount={props.artworksCount}
						/>
					</div>
				</DetailsContent>

				<DetailsImageContainer>
					{!image ?
						<div id="header-image" style={cs.header}>
							<SquareImageBox
								imageSrc={props.coverImageSrc}
								boxSideSize={cs.header.width}
							/>
						</div>
						: null
					}
				</DetailsImageContainer>
			</DetailsContentTablet>

			<DetailsContentPhone>
				<DetailsImageContainer>
					{!image ?
						<div id="header-image" style={cs.header}>
							<SquareImageBox
								imageSrc={props.coverImageSrc}
								boxSideSize={cs.header.width}
							/>
						</div>
						: null
					}
				</DetailsImageContainer>
				<DetailsContent>
					<div style={cs.iconStatusBarContainer}>
						<IconsStatusBarContainer
							albumUuid={props.uuid}
							likes={props.likes}
							timesVisited={props.timesVisited}
							artworksCount={props.artworksCount}
						/>
					</div>

					<IndicatorsContainer>
						<PostIndicator postedOn={props.postedOn} />
						{props.expiresOn && <StyledSeparator> | </StyledSeparator>}
						<ExpirationIndicator expiresOn={props.expiresOn} />
					</IndicatorsContainer>

					<TitleContainer style={cs.title}>{props.title}</TitleContainer>
				</DetailsContent>
			</DetailsContentPhone>

			<DescriptionContainer id="description" style={cs.description}>
				<p>{props.description}</p>
			</DescriptionContainer>
		</>
	);

	const artworkElements = props.artworks.map((artwork, index, array) => {
		const creationYear =
			artwork.creation &&
				artwork.creation.date &&
				artwork.creation.date.start.value &&
				artwork.creation.date.start.format
				? formatArtworkCreationDate(artwork.creation.date, 'YYYY', false)
				: null;
		return (
			<div
				onClick={() =>
					artworkPreviewClick(props.isPrivate, history, props.uuid, artwork._id)
				}
				key={index}
				style={{
					marginBottom: index === array.length - 1 ? '' : '3rem',
					cursor: 'pointer',
				}}>
				<StaticArtworkSnapshotBox
					imageSrc={getArtworkImageSrc(artwork.previews, getImageAssetUrl)}
					description={{
						artist: artwork.authors.length ? artwork.authors[0].name.full : 'Unknown',
						title: artwork.titles[0].value,
						...(creationYear && { creationYear: creationYear }),
					}}
				/>
			</div>
		);
	});

	const listElement = (
		<div style={cs.artworkList}>
			{props.artworksCount > 0 ?
				<ColumnVerticalContainer
					numberOfColumns={2}
					columnSpacing={defaultHorizontalMargin}>
					{artworkElements}
				</ColumnVerticalContainer>
				:
				<NoArtworkInfo>
					<span>No artworks</span>
				</NoArtworkInfo>
			}
			{/* {props.artworksCount > props.artworks.length ? (
				<LoadMoreBar click={() => props.loadMoreArtworks!()} />
			) : null} */}
		</div>
	);


	return (
		<div style={cs.container}>
			<div style={cs.tabsContainer}>
				<div
					onClick={(event) => {
						if (activeTabId !== 0) {
							tabScrollPositions.current = {
								details: tabScrollPositions.current.details,
								artworks: document.getElementById('content-container')!
									.scrollTop,
							};

							setTimeout(() => {
								document
									.getElementById('content-container')!
									.scroll(0, tabScrollPositions.current.details);
							});

							setActiveTabId(0);
						}
					}}
					style={cs.tab(activeTabId === 0)}>
					DETAILS
				</div>

				<div
					onClick={(event) => {
						if (activeTabId !== 1) {
							tabScrollPositions.current = {
								details: document.getElementById('content-container')!
									.scrollTop,
								artworks: tabScrollPositions.current.artworks,
							};

							setTimeout(() => {
								document
									.getElementById('content-container')!
									.scroll(0, tabScrollPositions.current.artworks);
							});

							setActiveTabId(1);
						}
					}}
					style={cs.tab(activeTabId === 1)}>
					ARTWORKS
				</div>
			</div>

			<div id="content-container" style={cs.contentContainer}>
				{!activeTabId ? detailsElement : listElement}
			</div>

			<ViewActionsPortal>
				<ActionsContainer>
					<div
						onClick={() => history.push('/')}
						style={actionStyle.navigationButton}>
						<img
							style={actionStyle.arrow}
							src="/icons/ico-showroom-arrow-left.svg"
						/>
						<span className="action_tablet" style={{ fontSize: '1.4rem' }}>
							All showrooms
						</span>
						<span className="action_mobile" style={{}}>
							Back
						</span>
					</div>
				</ActionsContainer>
			</ViewActionsPortal>
		</div>
	);
}
