import { componentStyle as smallPortraitComponentStyle } from '../../small-portrait/styles';
import { BoxSizing, Cursor } from '../../../../../ads-components/models';

const defaultOutlineColor = '#0089D4';
const defaultOutlineOffset = '3px';

export const componentStyle = {
	...smallPortraitComponentStyle,
	image: {
		...smallPortraitComponentStyle.image,
		height: '',
		padding: '3vh 20vw 0 20vw',
		boxSizing: BoxSizing.BorderBox,
	},
	previewBox: function (isSelected: boolean) {
		let opacity = '1';
		let outline = 'none';
		let outlineOffset = '0';
		let cursor = Cursor.Pointer;
		let minWidth: 'auto';

		if (!isSelected) {
			opacity = '.7';
		} else {
			outline = `${defaultOutlineOffset} solid ${defaultOutlineColor}`;
			outlineOffset = `-${defaultOutlineOffset}`;
			cursor = Cursor.Default;
			minWidth = 'auto';
		}

		return {
			marginRight: '1vw',
			opacity,
			outline,
			outlineOffset,
			cursor,
			minWidth: 'auto',
		};
	},
	navigationBar: {
		padding: '1.6rem 0 1.3rem 0',
		boxShadow: '0 0 10px rgb(0 0 0 / 15%)',
		position: 'relative',
	} as React.CSSProperties,
	previewList: {
		height: '12vw',
		boxSizing: BoxSizing.BorderBox,
		margin: '5vw 0 3vw 3vw',
	},
};
