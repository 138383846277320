import React from 'react';
import { componentStyle as cs, EmptyStateContainer, InfoBoxContainer, InfoText } from './styles';
import { arteiaLogoShowroomsSrc } from '../../../generic';
import { useHistory } from 'react-router-dom';
import { Props } from './models';
import { ButtonText, TextButtonContainer } from '../../../login/styles';
import { InfoBox, TextButton } from 'ads';
import { ButtonSize, Color } from 'ads/dist/src/components/models';
import { LogoImage, RightsContainer } from '../login/styles';

export function EmptyState(props: Props): JSX.Element {
	const history = useHistory();
	const currentYear = new Date().getFullYear()

	return (
		<EmptyStateContainer style={cs.wrapper}>
			<LogoImage>
				<img src={arteiaLogoShowroomsSrc} onClick={() => history.push('/')} />
			</LogoImage>

			<InfoBoxContainer style={cs.infoBoxExpiredShowroom}>
				<InfoText>
					{props.text}
				</InfoText>
				<TextButtonContainer>
					<TextButton
						onClick={() => {
							history.push('/');
						}}
						size={ButtonSize.SMALL}
						color={Color.DEFAULT}
						disabled={false}
						fullWidth={false}
						type="submit"
					>
						<ButtonText>
							Browse other public showrooms {'>'}
						</ButtonText>
					</TextButton>

				</TextButtonContainer>
			</InfoBoxContainer>
			<RightsContainer>
				<span>
					@ {currentYear} Arteïa - All rights reserved
				</span>
			</RightsContainer>
		</EmptyStateContainer>
	);
}
