import { FusionAuthClient, LoginResponse } from '@fusionauth/typescript-client';
import ClientResponse from '@fusionauth/typescript-client/build/src/ClientResponse';
import { Observable, of } from 'rxjs';
import { verifyJWT } from './utils';
import { cookieService } from './cookie.service';

export class AuthService {
	private fusionAuthClient: FusionAuthClient;

	constructor() {
		this.fusionAuthClient = new FusionAuthClient(
			'kUOFyioW9SiwY_lI_sTHgLH6TsVqp4ysZxWTBOBPSYg',
			'http://localhost:9011'
		);
	}

	isAuthenticated() {
		return !!cookieService.getToken();
	}

	login(loginId: string, password: string): Observable<boolean> {
		if (this.isAuthenticated()) {
			console.info('User is already authenticated');

			return of(true);
		}

		return new Observable((subscriber) => {
			this.fusionAuthClient
				.login({
					applicationId: '6583fc42-4c75-416d-beef-319ff9bc3433',
					loginId,
					password,
				})
				.then((cr: ClientResponse<LoginResponse>) => {
					if (cr.statusCode === 202 || cr.statusCode === 200) {
						if (verifyJWT(cr.response.token)) {
							cookieService.setToken(cr.response.token!);

							subscriber.next(this.isAuthenticated());
							subscriber.complete();
						} else {
							subscriber.error(false);
						}
					} else {
						subscriber.error(false);
					}
				})
				.catch((error: any) => {
					subscriber.error(this.isAuthenticated);
				});
		});
	}

	logout() {
		// return new Observable((subscriber) => {
		// 	this.fusionAuthClient
		// 		.logout()
		// 		.then((cr: ClientResponse<LoginResponse>) => {
		// 			if (cr.statusCode === 202 || cr.statusCode === 200) {
		// 				this.token = cr.response.token ? cr.response.token : null;
		// 				if (verifyJWT(this.token)) {
		// 					this.isAuthenticated = true;
		// 					subscriber.next(this.isAuthenticated);
		// 					subscriber.complete();
		// 				} else {
		// 					subscriber.error(false);
		// 				}
		// 			} else {
		// 				subscriber.error(false);
		// 			}
		// 		})
		// 		.catch((error: any) => {
		// 			subscriber.error(this.isAuthenticated);
		// 		});
		// });
	}
}
