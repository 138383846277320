import { Artwork } from '../services/models/api-models';

export function getArtworkCreationYear(artwork: Artwork): string {
	const creationDate: string = artwork.creation.date.start.value;

	if(isNaN(Date.parse(new Date(+creationDate).toString()))) {
		return ''
	}
	return new Date(+creationDate).getFullYear().toString();
}
