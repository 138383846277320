import { Error } from '../models/error-models';
import { ApiError } from '../models/api-models';

export function handleApiError(obj: any): Error | null {
	const apiError = obj as ApiError;

	if (apiError.errorId && apiError.message) {
		return Error.DocumentNotFound;
	}

	return null;
}
