import React from 'react';
import { getExpirationDate } from '../../../utils';
import { componentStyle as cs, ExpirationIndicatorContainer } from './styles';

interface Props {
	expiresOn: string | null;
}

export function ExpirationIndicator(props: Props) {
	const indicator = props.expiresOn ? (
		<ExpirationIndicatorContainer style={cs.wrapper}>
			<span>{getExpirationDate(props.expiresOn as string, true)}</span>
		</ExpirationIndicatorContainer>
	) : null;

	return indicator;
}
