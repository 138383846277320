import { APIModel } from './models';
import { new_showrooms_api_endpoint as API_ENDPOINT } from '../../env';
import { RequestMethod } from './models';

const config: RequestInit = {
	mode: 'cors',
	cache: 'no-cache',
	headers: {
		'Content-Type': 'application/json'
	},
}

export const ShowroomsAPI: APIModel = {
	get: async (url: string) => (
		fetch(
		`${API_ENDPOINT}${url}`,
			{
				...config,
				method: RequestMethod.GET,
			}
		)
			.then((response: Response) => {
				// NOTE: we have to verify if correct status has been returned
				if(response.ok) {
					// NOTE: we have to verify if request has correct body
					return response.json()
						.then((jsonResponse) => jsonResponse)
						.catch(() => ({}))
				} else {
					return Promise.reject(`Wrong response status: ${response.status}`);
				}
			})
			.then((responseJson) => responseJson)
			.catch((error) => {
				console.error("API error", error);
				return Promise.reject(error);
			})
	),
	post: async (url: string, body?: any, opts?: any) => (
		fetch(
			`${API_ENDPOINT}${url}`,
			{
				...config,
				method: RequestMethod.POST,
				body: JSON.stringify(body),
			}
		)
			.then((response: Response) => {
				// NOTE: we have to verify if correct status has been returned
				if(response.ok) {
					// NOTE: we have to verify if request has correct body
					return response.json()
						.then((jsonResponse) => jsonResponse)
						.catch(() => ({}))
				} else {
					return Promise.reject(`Wrong response status: ${response.status}`);
				}
			})
			.then((responseJson) => responseJson)
			.catch((error) => {
				console.error("API error", error);
				return Promise.reject(error);
			})
		),
	put: async (url: string, body?: any) => (
		fetch(
			`${API_ENDPOINT}${url}`,
			{
				...config,
				method: RequestMethod.PUT,
				body: JSON.stringify(body),
			}
		)
			.then((response: Response) => {
				// NOTE: we have to verify if correct status has been returned
				if(response.ok) {
					// NOTE: we have to verify if request has correct body
					return response.json()
						.then((jsonResponse) => jsonResponse)
						.catch(() => ({}))
				} else {
					return Promise.reject(`Wrong response status: ${response.status}`);
				}
			})
			.then((responseJson) => responseJson)
			.catch((error) => {
				console.error("API error", error);
				return Promise.reject(error);
			})
	),
	patch: async (url: string, body?: any) => (
		fetch(
			`${API_ENDPOINT}${url}`,
			{
				...config,
				method: RequestMethod.PATCH,
				body: JSON.stringify(body),
			}
		)
			.then((response: Response) => {
				// NOTE: we have to verify if correct status has been returned
				if(response.ok) {
					// NOTE: we have to verify if request has correct body
					return response.json()
						.then((jsonResponse) => jsonResponse)
						.catch(() => ({}))
				} else {
					return Promise.reject(`Wrong response status: ${response.status}`);
				}
			})
			.then((responseJson) => responseJson)
			.catch((error) => {
				console.error("API error", error);
				return Promise.reject(error);
			})
	),
	delete: async (url: string, body?: any) => (
		fetch(
			`${API_ENDPOINT}${url}`,
			{
				...config,
				method: RequestMethod.DELETE,
				body: JSON.stringify(body),
			}
		)
			.then((response: Response) => {
				// NOTE: we have to verify if correct status has been returned
				if(response.ok) {
					// NOTE: we have to verify if request has correct body
					return response.json()
						.then((jsonResponse) => jsonResponse)
						.catch(() => ({}))
				} else {
					return Promise.reject(`Wrong response status: ${response.status}`);
				}
			})
			.then((responseJson) => responseJson)
			.catch((error) => {
				console.error("API error", error);
				return Promise.reject(error);
			})
	)
}
