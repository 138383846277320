import { Artwork } from '../../../../../../../services/models/api-models';
import { Tab } from '../../../../../../../ads-components/boxes/tabbed-text-box/models';
import { ArtworkDetailsTab } from '../../models';
import React from 'react';
import { EmptyStateContainer } from './styles';
import { disableQuill } from '.';

export function createLiteratureTab(
	artwork: Artwork
): Tab<ArtworkDetailsTab> | null {
	if (artwork.literature) {
		return {
			title: 'Literature',
			content: disableQuill(artwork.literature),
			id: ArtworkDetailsTab.literature,
		};
	} else {
		return {
			title: 'Literature',
			content: <EmptyStateContainer>No information</EmptyStateContainer>,
			id: ArtworkDetailsTab.literature,
		};
	}
}
