import { ArtworkDataSection} from './artwork-data-section.model';

export interface ArtworkDisplayedSections {
	[ArtworkDataSection.BASIC_DETAILS]: boolean,
	[ArtworkDataSection.MATERIALS_TECHNIQUES]: boolean,
	[ArtworkDataSection.EDITION]: boolean,
	[ArtworkDataSection.INSCRIPTION_MARKS]: boolean,
	[ArtworkDataSection.DIMENSIONS]: boolean,
	[ArtworkDataSection.PROVENANCE]: boolean,
	[ArtworkDataSection.LITERATURE]: boolean,
	[ArtworkDataSection.EXHIBITIONS]: boolean,
	[ArtworkDataSection.DESCRIPTION]: boolean
}
