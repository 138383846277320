import { InquireModalField, InquireModalFields } from '../models';

export const fieldsInitialState: InquireModalFields = {
	[InquireModalField.email]: {
		value: '',
		valid: true,
		errorMessage: null,
		disabled: false,
	},
	[InquireModalField.phone]: {
		value: '',
		valid: true,
		errorMessage: null,
		disabled: false,
	},
	[InquireModalField.name]: {
		value: '',
		valid: true,
		errorMessage: null,
		disabled: false,
	},
	[InquireModalField.message]: {
		value: "Hi, I’m interested in purchasing this work. Could you please provide more information about this piece?",
		valid: true,
		errorMessage: null,
		disabled: false,
	},
}
