import { textColor } from '../../../../../styles';
import {
	BoxSizing,
	Display,
	FlexDirection,
	FontStyle,
} from '../../../../models';
import { Overflow } from '../../../../models/overflow.model';

export const componentStyle = {
	wrapper: {
		display: Display.Flex,
		boxSizing: BoxSizing.BorderBox,
		flexDirection: FlexDirection.Column,
		whiteSpace: 'nowrap',
	} as React.CSSProperties,
	artist: {
		color: textColor,
		paddingBottom: '0.4rem',
		fontSize: '1.5rem',
		lineHeight: '1.8rem',
		overflowX: Overflow.Auto,
		fontWeight: 600,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	title: {
		color: textColor,
		fontStyle: FontStyle.Italic,
		paddingBottom: '0.2rem',
		fontSize: '1.3rem',
		lineHeight: '1.6rem',
		overflowX: Overflow.Auto,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		maxWidth: 'calc(100% - -75px)',
		minWidth: 'calc(100% - -1px)'
	},
	creation: {
		color: textColor,
		paddingBottom: '0.2rem',
		fontSize: '1.3rem',
		lineHeight: '1.6rem',
		overflowX: Overflow.Auto,
		flex: '0 0 auto'
	},
	inventoryNumber: {
		fontSize: '0.8rem',
		color: '#5D5E5F',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
};
