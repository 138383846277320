import { AlbumViewModel } from '../models';
import {
	Album as AlbumApiModel,
	Artwork,
	Album,
} from '../services/models/api-models';
import {
	missingArtworkIndicator,
	missingArtworkIndicatorSmall,
} from '../components/generic';
import { albumContainsArtworks as albumContainsAnyLoadedArtwork } from './album-contains-artworks';
import { api_assets_endpoint } from '../env';

const imageApiEndpoint = api_assets_endpoint;

export function getAlbumViewModel(
	album: AlbumApiModel<Artwork>,
	onlyAlbumCover: boolean = false,
	loadMoreArtworksFn?: Function,
	isPrivate?: boolean
): AlbumViewModel {
	let coverImageSrc: string = '';

	coverImageSrc =
		album.cover && album.cover.small
			? `${imageApiEndpoint}/${album.cover.small}`
			: // NOTE: When cover image is missing we try to fall back to first available artwork preview
			!onlyAlbumCover
			? getCoverImageSrc(album)
			: missingArtworkIndicator;

	function getCoverImageSrc(album: Album<Artwork>) {
		const foundArtwork = album.artworks.find((artwork) => {
			return artwork.previews[0] && artwork.previews[0].small;
		});

		const output = foundArtwork
			? `${imageApiEndpoint}/${(foundArtwork as Artwork).previews[0].small}`
			: missingArtworkIndicator;

		return output;
	}

	const previews: {
		url: string;
		artworkId: string;
	}[] = albumContainsAnyLoadedArtwork(album)
		? // NOTE: Remember about undefined slots for artworks that has not been loaded yet.
		  album.artworks.map((artwork) =>
				artwork.previews[0] && artwork.previews[0].small
					? {
							url: `${imageApiEndpoint}/${artwork.previews[0].small}`,
							artworkId: artwork._id,
					  }
					: {
							url: missingArtworkIndicatorSmall,
							artworkId: artwork._id,
					  }
		  )
		: [];

	const artworks = albumContainsAnyLoadedArtwork(album) ? album.artworks : [];

	return {
		id: album._id,
		uuid: album.uuid,
		coverImageSrc,
		previews: previews,
		title: album.name,
		description: album.description || '',
		postedOn: album.creationDate,
		forSale: album.forSale,
		expiresOn: album.expirationDate,
		albumLink: '',
		artworks,
		timesVisited: album.timesVisited || null,
		artworksCount: album.artworksCount,
		likes: album.likes,
		loadMoreArtworks: loadMoreArtworksFn,
		isPrivate: isPrivate || false,
	};
}
