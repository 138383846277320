import { FontWeight, FontFamily, WhiteSpace, Cursor } from '../../../models';
import { blueColor } from '../../../../styles';

export const componentStyle = {
	border: 'none',
	borderRadius: '4rem',
	fontSize: '1.4rem',
	lineHeight: '2rem',
	padding: '1rem 2.5rem',
	fontWeight: FontWeight.Bold,
	color: blueColor,
	cursor: Cursor.Pointer,
	boxShadow: '0 0 4px 0 rgba(0,0,0,0.2)',
	fontFamily: FontFamily.Lato,
	whiteSpace: WhiteSpace.Nowrap,
	transition: '.0s',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
};
