import { ViewDataModel } from './models';

export class ViewDataService {
	data: ViewDataModel = {
		list: {
			scrollPosition: 0,
		},
		home: {},
	};

	setListScrollPosition(scrollPosition: number) {
		this.data.list.scrollPosition = scrollPosition;
	}

	setHomeScrollPosition(albumUuid: string, scrollPosition: number) {
		this.data.home[albumUuid] = scrollPosition;
	}
}
