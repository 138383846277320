import { ActionDropdown, TextButton } from 'ads';
import { Align, Background, ButtonSize, Color } from 'ads/dist/src/components/models';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { LogoToolbar } from '../../ads-components/bars/toolbars/logo-toolbar/LogoToolbar';
import { AppContext } from '../../context/context';
import { arteiaLogoShowroomsSrc } from '../generic';
import { ButtonsContainer, ButtonText, componentStyle as cs, TextButtonContainer, ToolbarActions, ToolbarMobileActions, ToolbarWrapperContainer } from './styles';
const logoUrl = arteiaLogoShowroomsSrc;

export function TopBar() {
	const context = useContext(AppContext);
	const history = useHistory();
	const options = [
		// { id: 'login', label: 'Log in', action: () => history.push('login') },
		{ id: 'create_account', label: 'Create account', action: () => window.open('https://secure.arteia.com/sign-up') },
	];
	const [navbar, setNavbar] = useState<boolean>(false);

	const addShadow = () => {
		// console.log(window.scrollY);

		if (window.scrollY >= 80) {
			setNavbar(true)
		} else {
			setNavbar(false)
		}
	}

	window.addEventListener('scroll', addShadow)
	return (
		<ToolbarWrapperContainer id="toolbar-wrapper" style={cs.wrapper}>
			<LogoToolbar onLogoClick={() => history.push('/')} imageUrl={logoUrl}>
				<ToolbarActions id="toolbar-actions">
					{/* NOTE: Portal entry point */}
					<div id="top-bar-portal"></div>
					<span style={{ flex: '1' }}></span>
					{/* <AvatarMenuButton
						avatarImageUrl={
							'https://ca.slack-edge.com/T6302FKNW-U92AWQG3X-9e482086a676-512'
						}></AvatarMenuButton>{' '} */}

					{!context.authService.isAuthenticated() ? (
						<>
							<ButtonsContainer>
								{/* NOTE: Left for feature development */}
								{/*<TextButtonContainer>*/}
								{/*	<TextButton*/}
								{/*		onClick={() => history.push('login')}*/}
								{/*		size={ButtonSize.MEDIUM}*/}
								{/*		color={Color.SECONDARY}*/}
								{/*		disabled={false}*/}
								{/*		fullWidth={false}*/}
								{/*		type="submit"*/}
								{/*		underlined={false}*/}
								{/*	>*/}
								{/*		<ButtonText>LOG IN</ButtonText>*/}
								{/*	</TextButton>*/}
								{/*</TextButtonContainer>*/}
								<TextButtonContainer>
									<TextButton
										onClick={() => window.open('https://secure.arteia.com/sign-up')}
										size={ButtonSize.MEDIUM}
										color={Color.SECONDARY}
										disabled={false}
										fullWidth={false}
										type="submit"
										underlined={false}
									>
										<ButtonText>CREATE ACCOUNT</ButtonText>
									</TextButton>
								</TextButtonContainer>
							</ButtonsContainer></>
					) : (
						<button onClick={() => context.authService.logout()}>Logout</button>
					)}
				</ToolbarActions>
				<ToolbarMobileActions>
					<ActionDropdown options={options} align={Align.RIGHT} withBg={false} hoverBackground={Background.LIGHT}><img src="/icons/ico-menu.svg"></img></ActionDropdown>
				</ToolbarMobileActions>
			</LogoToolbar>
		</ToolbarWrapperContainer>
	);
}
