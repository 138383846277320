import { Artwork } from '../services/models/api-models';

export function getArtworkEditionNumber(artwork: Artwork): string | null {
	if (artwork.edition && !artwork.edition.isUnique) {
		if (artwork.edition?.number && artwork.edition?.total) {
			return `Edition ${artwork.edition?.number}/${artwork.edition?.total}`;
		} else if (!artwork.edition?.number && artwork.edition?.total) {
			return `Edition ${artwork.edition?.total}`;
		} else if (artwork.edition?.number && !artwork.edition?.total) {
			return `Edition ${artwork.edition?.number}`;
		} else {
			return null;
		}
	} else {
		return null;
	}
}
