import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AppContextProvider } from '../../context/AppContextProvider';
import { List } from '../list/List';
import { TopBar } from '../top-bar/TopBar';
import { EmptyState } from '../private/components/empty-state';
import { Login } from '../login/Login';
import { collect, ToastWrapper } from 'ads/dist';
import { ThemeProvider } from 'styled-components';
import { PublicHome } from '../rewritten-components/PublicHome';
import { PrivateHome } from '../private/components/home/PrivateHome';
import { PrivateShowroomLogin } from '../private/components/login/Login';
import { PublicDetails } from '../rewritten-components/PublicDetails';
import { PrivateDetails } from '../rewritten-components/PrivateDetails';


export default function () {
	const ThemeGlobalStyles = collect.variables.globalStyles;

	return (
		<AppContextProvider>

			<ThemeGlobalStyles />
			<ThemeProvider theme={collect}>
				<ToastWrapper />
				<BrowserRouter>
					<React.Fragment>
						<Switch>
							<Route exact path="/">
								<TopBar />
								<List />
							</Route>

							<Route exact path="/login">
								<Login />
							</Route>

							<Route exact path="/public/404">
								<EmptyState text="The public showroom you are looking for doesn't exist." />
							</Route>

							<Route exact path="/public/:uuid/:artworkId/:previewIndex">
								<TopBar />
								<PublicDetails />
							</Route>

							<Route exact path='/public/not-available'>
								<EmptyState text="The public showroom you are looking for is not available anymore." />
							</Route>

							<Route exact path={[
								"/public/:uuid",
								"/public/:uuid/artworks"
							]}>
								<TopBar />
								<PublicHome />
							</Route>

							<Route exact path='/private/404'>
								<EmptyState text="The private showroom you are looking for doesn't exist." />
							</Route>

							<Route exact path="/private/:uuid/:artworkId/:previewIndex">
								<TopBar />
								<PrivateDetails />
							</Route>

							<Route exact path='/private/not-available'>
								<EmptyState text="The private showroom you are looking for is not available anymore." />
							</Route>

							<Route exact path="/private/:uuid/login">
								<PrivateShowroomLogin />
							</Route>

							<Route exact path={[
								"/private/:uuid",
								"/private/:uuid/artworks"
							]}>
								<TopBar />
								<PrivateHome />
							</Route>

						</Switch>
					</React.Fragment>
				</BrowserRouter>
			</ThemeProvider>
		</AppContextProvider>
	);
}
