import React from 'react';
import { ArtworkInShowroomStatus } from '../../../../../models';
import { InquireModal } from '../../../../inquire-modal';
import { SoldIndicator } from '../../components';
import { componentStyle as cs } from '../artwork-details/styles';
import { getArtworkPrice } from '../../../../../utils';
import { Props } from './models/props.model';

export function SalesDetails(props: Props): JSX.Element {
	return (
		<>
			{props.artwork.status === ArtworkInShowroomStatus.FOR_SALE && (
				<>
					{props.artwork.price && (
						<div style={cs.priceContainer}>
							{getArtworkPrice(props.artwork.price)}
						</div>
					)}
					<InquireModal artwork={props.artwork} />
				</>
			)}
			{props.artwork.status === ArtworkInShowroomStatus.SOLD && (
				<>
					{props.artwork.price && (
						<div style={cs.priceContainer}>
							{getArtworkPrice(props.artwork.price)}
						</div>
					)}
					<SoldIndicator />
				</>
			)}
		</>
	);
}
