export enum FlexWrap {
	Inherit = 'inherit',
	Initial = 'initial',
	Revert = 'revert',
	Unset = 'unset',
	MozInitial = '-moz-initial',
	Wrap = 'wrap',
	Nowrap = 'nowrap',
	WrapReverse = 'wrap-reverse',
}
