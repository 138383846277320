import styled, {css} from "styled-components";
import {Props} from "../model";


const overlayStyles = css<any>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({overlay}) => overlay};
  align-items: center;
  justify-content: center;
`;

const StyledLoader = styled.div<Props>`
  display: flex;
  flex-direction: column;
  ${({fullSize}) => fullSize ? 'height: 100%;' : null};
  ${({fullSize}) => fullSize ? 'width: 100%;' : null};
  ${({centered}) => centered ? 'justify-content: center;' : null};
  ${({centered}) => centered ? 'align-items: center;' : null};

  ${({overlay}) => overlay ? overlayStyles : null};

  img {
    ${({size}) => size && size === 'small' ? 'width: 2.4rem; height: 2.4rem;' : null};
  }
`;

export {
  StyledLoader,
}
