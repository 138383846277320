export enum Languages {
	ar = 'ar',
	cz = 'cz',
	de = 'de',
	en = 'en',
	es = 'es',
	fr = 'fr',
	ger = 'ger',
	it = 'it',
	jp = 'jp',
	pl = 'pl',
	ru = 'ru',
	kor = 'kor',
	nld = 'nld'
}
