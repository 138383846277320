import {
	WebkitLineClamp,
	WebkitBoxOrient,
	Position,
	Visibility,
} from '../../../models';

export class ComponentStyle {
	contentContainer: ContentContainer;
	initialHeightContainer = {
		position: Position.Absolute,
		visibility: Visibility.Hidden,
	};

	constructor(maxLines: string) {
		this.contentContainer = new ContentContainer(+maxLines);
	}
}

export class ContentContainer {
	WebkitLineClamp: 3;

	overflow = 'hidden';
	textOverflow = 'ellipsis';
	display = '-webkit-box';
	WebkitBoxOrient = WebkitBoxOrient.Vertical;

	constructor(maxLines: number) {
		this.WebkitLineClamp = 3;
	}
}
