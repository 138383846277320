import { BoxSizing, Display, FlexDirection } from '../../../models';

export const componentStyle = {
	wrapper: {
		display: Display.Flex,
		boxSizing: BoxSizing.BorderBox,
		flexDirection: FlexDirection.Column,
	},
	imageBox: {
		height: '100%',
		marginBottom: '1rem',
	},
};
