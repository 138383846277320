import { useEffect, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

export function ViewActionsPortal(props: PropsWithChildren<any>) {
	const mountElement = document.getElementById('top-bar-portal');
	const el = document.createElement('div');

	useEffect(() => {
		mountElement!.appendChild(el);

		return () => {
			mountElement!.removeChild(el);
		};
	}, [el, mountElement]);

	return createPortal(props.children, el);
}
