import React from 'react';
import { PrivateAlbumService } from '../services/private-album.service';
import { PublicAlbumService } from '../services/public-album.service';
import { AppContext as AppContextModel } from './models';
import { getViewportType } from './utils/get-viewport-type';
import { ViewDataService } from '../services/view-data.service';
import { AuthService } from '../services/auth.service';
import { ShowroomService } from '../services/showroom.service';

const defaultAppState: AppContextModel = {
	userProfile: null,
	viewportType: getViewportType(window.outerWidth, window.outerHeight),
	dimensions: {
		width: window.outerWidth,
		height: window.outerHeight,
	},
	privateAlbumService: new PrivateAlbumService(),
	publicAlbumService: new PublicAlbumService(),
	viewDataService: new ViewDataService(),
	authService: new AuthService(),
	showroomService: new ShowroomService()
};

const AppContext = React.createContext<AppContextModel>(defaultAppState);

export { defaultAppState, AppContext };
