import styled from 'styled-components';
import {
	AlignItems,
	BoxSizing,
	Cursor,
	Display,
	FlexDirection,
	FontWeight,
	JustifyContent,
	TextAlign,
} from '../../../ads-components/models';
import { labelColor } from '../../../styles';
import Breakpoints from '../../../styles/Breakpoints';
// TODO: Extract styles and unify with private album login page?
import { defaultStyle } from '../../private/styles';

export const componentStyle = {
	...defaultStyle,
	infoText: {
		...defaultStyle.infoText,
		lineHeight: '23px',
		fontSize: '18px',
		color: '#010304',
	},
	inputLabel: {
		fontSize: '1.6rem',
		lineHeight: '1.6rem',
		marginBottom: '5px',
		color: labelColor,
	},
	inputWrapper: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		width: '100%',
	},
	input: {
		width: '100%',
		height: '2.5rem',
		fontSize: '2rem',
		border: 'solid 2px #E0E0E3',
		marginBottom: '1rem',
	},
	submitButton: {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		alignItems: AlignItems.Center,
		backgroundColor: '#39BBF2',
		color: 'white',
		fontSize: '1.4rem',
		lineHeight: '2.1rem',
		fontWeight: FontWeight.Bold,
		padding: '1rem',
		boxShadow: '0 0 5px 0 rgba(20,75,99,0.17)',
		boxSizing: BoxSizing.BorderBox,
		width: '90px',
		height: '40px',
		textAlign: TextAlign.Center,
		borderRadius: '25px',
		cursor: Cursor.Pointer,
	},
	error: {
		color: '#BD4158',
		fontSize: '13px',
		lineHeight: '16px',
	},
};

const ButtonText = styled.span`
	font-weight: 300;

	@media all and (max-width: ${Breakpoints.phone}) {
        font-size: 1.6rem !important;
    }
`;

const TextButtonContainer = styled.div`
	text-align: center;
`;

const LoginWrapper = styled.div`

	@media all and (max-width: ${Breakpoints.phone}) {
        background: #fff !important;
    }
`;

const InfoBoxLogin = styled.div`

	@media all and (max-width: ${Breakpoints.phone}) {
       	box-shadow: none !important;
		margin: 0 !important;
		padding: 1.6rem 1rem !important;
		width: 100% !important;
    }
`;

const InfoText = styled.div`
	@media all and (max-width: ${Breakpoints.phone}) {
		font-weight: bold;
		font-size: 2rem !important;
		margin-bottom: 3.3rem !important;
	}
`;

const SubmitButton = styled.div`

	span{
		margin-right: 0.5rem;
	}

	@media all and (max-width: ${Breakpoints.phone}) {
		height: 4.2rem!important;
		width: 10.2rem!important;

		span{
			font-size: 1.6rem;
		}
	}
`;

export{
	ButtonText,
	TextButtonContainer,
	LoginWrapper,
	InfoBoxLogin,
	InfoText,
	SubmitButton
}
