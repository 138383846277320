import { goToPrivateArtworkDetails, goToArtworkDetails } from '.';

export function artworkPreviewClick(
	isPrivate: boolean,
	history: any,
	albumUuid: string,
	artworkId: string
) {
	isPrivate
		? goToPrivateArtworkDetails(history, albumUuid, artworkId)
		: goToArtworkDetails(history, albumUuid, artworkId);
}
