import React, { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { LogoIndicator } from '../../ads-components/indicators/logo-loading-indicator/LogoLoadingIndicator';
import { Effect } from '../../ads-components/indicators/logo-loading-indicator/models';
import { AppContext } from '../../context/context';
import {
	AppContext as AppContextModel,
	ViewportType,
} from '../../context/models';
import { ViewState } from '../../models';
import { Album, Artwork } from '../../services/models/api-models';
import { defaultLogoIndicatorSize } from '../../styles';
import { getAlbumViewModel } from '../../utils';
import { LoadMoreBar } from '../generic/load-more-bar/LoadMoreBar';
import { ListItemSmallPortrait } from './list-item/views';
import { ListItemLargeLandscape } from './list-item/views/large-landscape/ListItemLargeLandscape';
import { componentStyle as cs, ListContainer } from './styles';
import { GetPublicShowroomsResponse } from '../../services/showroom.service';
import { ListItemMediumPortrait } from './list-item/views/medium-portrait/ListItemMediumPortrait';

const listWrapperId: string = 'list-wrapper';

function getView(viewportType: ViewportType, albums: Album<Artwork>[]) {
	if (!albums.length) {
		return (
			<div style={cs.noArtworks}>
				<h3>No albums</h3>
			</div>
		);
	} else if (
		viewportType === +ViewportType.SmallPortrait ||
		viewportType === +ViewportType.SmallLandscape
	) {
		return albums.map((album) => {
			const props = getAlbumViewModel(album);

			return (
				<ListItemSmallPortrait
					key={props.id}
					{...props}></ListItemSmallPortrait>
			);
		});
	} else if (
		viewportType === +ViewportType.MediumPortrait ||
		viewportType === +ViewportType.MediumLandscape
	) {
		return albums.map((album) => {
			const props = getAlbumViewModel(album);

			return (
				<ListItemMediumPortrait
					key={props.id}
					{...props}></ListItemMediumPortrait>
			);
		});
	} else {
		return albums.map((album) => {
			const props = getAlbumViewModel(album);
			return (
				<ListItemLargeLandscape
					key={props.id}
					{...props}></ListItemLargeLandscape>
			);
		});
	}
}

export function List() {
	const componentSubs: Subscription = new Subscription();
	const context: AppContextModel = useContext(AppContext);

	const [albums, setAlbums] = useState<Album<Artwork>[]>([]);
	const [viewState, setViewState] = useState<ViewState>(ViewState.Loading);
	const [loadMore, setLoadMore] = useState<boolean>(true);
	const [pageSize, setPageSize] = useState<number>(2);
	const [pageNumber, setPageNumber] = useState<number>(0);

	useEffect(() => () => componentSubs.unsubscribe(), []);

	function loadAlbums() {
		componentSubs.add(
			context.showroomService
				.getPublicShowroomsObs(pageNumber, pageSize)
				.subscribe(
					(data: GetPublicShowroomsResponse) => {
						// if (data.albums.length + albums.length === data.total) {
						// 	setLoadMore(false);
						// }

						setAlbums((prev: Album<Artwork>[]) => [...prev, ...data.albums]);
						setViewState(ViewState.Ok);
					},
					(error) => {
						setViewState(ViewState.Error);
					}
				)
		);
	}

	useEffect(() => {
		loadAlbums();
	}, [pageNumber]);

	function loadMoreAlbums() {
		setPageNumber((prev: number) => prev + 1);
	}

	return (
		<>
			{viewState === ViewState.Loading || viewState === ViewState.Error ? (
				<div style={cs.viewStateIndicator}>
					<LogoIndicator
						width={defaultLogoIndicatorSize}
						effect={
							viewState === ViewState.Loading ? Effect.Pulse : Effect.Static
						}>
						{viewState}
					</LogoIndicator>
				</div>
			) : (
				<ListContainer id={listWrapperId} style={cs.wrapper}>
					{getView(context.viewportType, albums)}
					{/* {loadMore ? (
						<LoadMoreBar
							click={() => loadMoreAlbums()}
						/>
					) : null} */}
				</ListContainer>
			)}
		</>
	);
}
