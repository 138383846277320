export enum WhiteSpace {
	Nowrap = 'nowrap',
	Pre = 'pre',
	MozInitial = '-moz-initial',
	Inherit = 'inherit',
	Initial = 'initial',
	Revert = 'revert',
	Unset = 'unset',
	Normal = 'normal',
	MozPreWrap = '-moz-pre-wrap',
	BreakSpaces = 'break-spaces',
	PreLine = 'pre-line',
	PreWrap = 'pre-wrap',
}
