import { ArtworkCreationDateEvent } from '../services/models/api-models';
import _ from 'lodash';
import moment from 'moment';

const getMomentDateFormat = (dataFormat: string) => {
	switch (dataFormat) {
		case 'DD-MM-YYYY':
			return 'D MMM YYYY';
		case 'MM-YYYY':
			return 'MMM YYYY';
		case 'YYYY':
			return 'YYYY';
		default:
			return dataFormat;
	}
};

export const convertToRoman = (date: string) => {
	if (date && date !== 'Incorrect date') {
		const convertedDate = Number(new Date(date).getFullYear());
		let convertedNum = Math.ceil(convertedDate / 100);
		const roman: any = {
			XL: 40,
			X: 10,
			IX: 9,
			V: 5,
			IV: 4,
			I: 1,
		};
		let str = '';

		for (const i of Object.keys(roman)) {
			const q = Math.floor(convertedNum / roman[i]);
			convertedNum -= q * roman[i];
			str += i.repeat(q);
		}
		return str;
	} else {
		return 'Incorrect date';
	}
};

export function formatArtworkCreationDate(
	date: {
		start: ArtworkCreationDateEvent;
		end: ArtworkCreationDateEvent;
		range: boolean;
	} | null,
	expectedFormat: string | null = null,
	shouldRangeDateBeBracket: boolean = true,
): string {
	if (!date) {
		return '';
	}

	const dataDateFormat = _.get(date, 'start.format');
	const isCirca = _.get(date, 'circa', false);
	const isRanged = _.get(date, 'range', false);
	const isCenturyFormat = dataDateFormat === 'century';
	let circaPrefix = '';

	if (isCirca) {
		circaPrefix += 'c. ';
	}

	const startDate =
		date.start && date.start.value
			? moment(date.start.value).format(
					getMomentDateFormat(
						isCenturyFormat ? 'YYYY' : expectedFormat || dataDateFormat
					)
			  )
			: 'Incorrect date';

	const endDate =
		date.end && date.end.value
			? moment(date.end.value).format(
					getMomentDateFormat(
						isCenturyFormat ? 'YYYY' : expectedFormat || dataDateFormat
					)
			  )
			: 'Incorrect date';

	if (isCenturyFormat) {
		return isRanged
			? `${shouldRangeDateBeBracket ? '(' : ''}${circaPrefix}${convertToRoman(
					startDate
			  )} - ${circaPrefix}${convertToRoman(endDate)} ${'century'}${
					shouldRangeDateBeBracket ? ')' : ''
			  }`
			: `${circaPrefix}${convertToRoman(startDate)} ${'century'}`; // TODO: change to use translations
	}

	return isRanged
		? `${circaPrefix}${
				shouldRangeDateBeBracket ? '(' : ''
		  }${startDate} - ${endDate}${shouldRangeDateBeBracket ? ')' : ''}`
		: `${circaPrefix}${startDate}`;
}
