import { Preview } from '../services/models/api-models/preview.model';
import { missingArtworkIndicator } from '../components/generic';

// NOTE: Each artwork 'snapshot' is based on a preview ex. album home page grid
export function getArtworkImageSrc(previews: Preview[], urlUpdateFn: Function) {
	if (!previews || !previews.length) {
		return missingArtworkIndicator;
	}

	const preview = previews.find((preview) => preview.small);

	return preview ? urlUpdateFn(preview.small) : missingArtworkIndicator;
}
