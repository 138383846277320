export const componentStyle = {
	wrapper: {
		overflowWrap: 'break-word',
	} as React.CSSProperties,
	priceContainer: {
		margin: '2rem 0',
	},
	tagsContainer: {
		marginTop: '2rem',
	},
	actionsButtonContainer: {
		marginTop: '2rem',
		display: 'flex',
	},
};
