import styled from 'styled-components';
import { Cursor, TextAlign } from '../../../../../ads-components/models';
import { textColor } from '../../../../../styles';
import Breakpoints from '../../../../../styles/Breakpoints';
import { defaultStyle } from '../../../styles';

export const componentStyle = {
	...defaultStyle,
	browseText: {
		fonSize: '14px',
		lineHeight: '18px',
		textAlign: TextAlign.Center,
		color: textColor,
		textDecoration: 'underline',
		cursor: Cursor.Pointer,
	},
};

const InfoText = styled.span`
	text-align: center;
	font-size: 2rem;
	line-height: 2.8rem;
	color: ${textColor};
	font-style: italic;
	margin-bottom: 1.9rem;

	@media all and (max-width: ${Breakpoints.phone}) {
		margin-bottom: -4.5rem !important;
	}
`;

const EmptyStateContainer = styled.div`
	@media all and (max-width: ${Breakpoints.phone}) {
		background: #fff !important;
		justify-content: unset !important;
		margin-top: 6rem;
		overflow: hidden;
	}
`;

const InfoBoxContainer = styled.div`
	@media all and (max-width: ${Breakpoints.phone}) {
		box-shadow: none !important;
		padding: 0 5rem !important;
		margin: 0 !important;
	}
`;

export {
	InfoText,
	EmptyStateContainer,
	InfoBoxContainer
}
