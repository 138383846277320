import { ImperialUnitLabelEn, MetricUnitLabelEn } from '../models';
import { round } from '../../../utils';
import { Labels } from '../../../models/globals';


const InchToCentimeter: number = 2.54;

// NOTE: we currently use only allow to use inch as only imperial unit
export function convertImperialDimensionToMetric(
	value: string | number,
	unit: {
		id: string,
		labels: Labels,
	},
	resultUnit: MetricUnitLabelEn,
	roundPlaces: number = 2,
): string | null {
	if (resultUnit === MetricUnitLabelEn.cm) {
		switch (unit.labels.en) {
			case ImperialUnitLabelEn.in:
			case ImperialUnitLabelEn.inch:
			case ImperialUnitLabelEn.Inch:
				return `${round(+value * InchToCentimeter, roundPlaces)}`;
			default:
				console.warn('Result unit: ', resultUnit, ' case not covered');
				return null;
		}
	}
	console.warn('Conversion from unit', unit, ' to: ', resultUnit, ' not implemented.');
	return null;

}
