import React, { useEffect } from 'react';
import { useState } from 'react';
import { createArtworkDetailsTabs } from '.';
import { Tab } from '../../../../../ads-components/boxes/tabbed-text-box/models';
import { TabbedTextBox } from '../../../../../ads-components/boxes/tabbed-text-box/TabbedTextBox';
import { ArtworkDetailsTab } from './models/artwork-details-tab.model';
import { Props } from './models/props.model';
import { StyledComponent } from './utils/helpers/styles';


export function ArtworkDetailsTabs(props: Props): JSX.Element {
	const [tabs, setTabs] = useState<Tab<ArtworkDetailsTab>[]>([]);

	useEffect(() => {
		setTabs(createArtworkDetailsTabs(props.artwork));
	}, [props.artwork])

	return (
		<div style={{
			margin: '4rem 0 18rem 0',
		}}>
			<StyledComponent>
				<div className="expanded">
					<TabbedTextBox tabs={tabs} />
				</div>

				<div className="not_expanded">
					<TabbedTextBox tabs={tabs} defaultTabId={ArtworkDetailsTab.provenance} />
				</div>
			</StyledComponent>
		</div>
	);
}
