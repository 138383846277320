import styled from 'styled-components';
import {
	Display,
	JustifyContent,
	Cursor,
} from '../../../../../../ads-components/models';
import Breakpoints from '../../../../../../styles/Breakpoints';

export const componentStyle = {
	wrapper: {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		width: '100%',
	},
	arrow: function (active: boolean) {
		return {
			cursor: active ? Cursor.Pointer : Cursor.Default,
			opacity: active ? '1' : '.2',
		};
	},
	numberDisplay: {
		fontSize: '1.4rem',
		padding: '0 1.5rem',
	},
};

const ArrowContainer = styled.div`
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 1rem;
	margin: -0.8rem -1rem;
	cursor: pointer;

	@media all and (max-width: ${Breakpoints.tablet}) {
		width: 4rem;
		display: flex;
		justify-content: center;
	}
`;

export { ArrowContainer };
