import {
	BoxSizing,
	Display,
	AlignItems,
	JustifyContent,
	FontWeight,
} from '../../../models';
import { blueColor, mutedColor, textColor } from '../../../../styles';
import styled from 'styled-components';
import Breakpoints from '../../../../styles/Breakpoints';

const activeColor = blueColor;
const inactiveColor = '#F6F6F6';
export class TabHeaderStyle {
	borderBottom: string;

	display = Display.Flex;
	alignItems = AlignItems.Center;
	justifyContent = JustifyContent.SpaceAround;
	boxSizing = BoxSizing.BorderBox;
	flex = '1 1 auto';
	padding = '0.5rem';
	cursor = 'pointer';
	color = textColor;
	fontWeight = FontWeight.Bolder;

	constructor(active: boolean) {
		this.borderBottom = active
			? `2px solid ${activeColor}`
			: `2px solid ${inactiveColor}`;
		this.color = active ? blueColor : textColor;
	}
}

const TabMobileContainer = styled.div`
	.unactive_tab {
		pointer-events: none;
		color: ${mutedColor};
	}

	.active {
		font-weight: bold;
	}
`;

const TabMobile = styled.div`
	border-top: 1px solid #f6f6f6;
	padding: 1.1rem 0;
	color: ${textColor};
	font-size: 1.6rem;
	display: flex;
	text-transform: uppercase;

	div {
		margin-left: auto;
	}
	.arrow_up {
		transform: rotateX(180deg);
	}
	.no_arrow {
		display: none;
	}
`;

const AccordionContainer = styled.div`
	.content {
		height: 0;
		opacity: 0;
	}

	.content_active {
		height: 0;
		opacity: 0;
	}
`;

const TabsContainer = styled.div`
	#container {
		@media all and (max-width: 766px) {
			display: none;
		}
	}

	#container-mobile {
		@media all and (min-width: ${Breakpoints.phone}) {
			display: none;
		}
	}
`;

export { TabMobileContainer, TabMobile, AccordionContainer, TabsContainer };
