import { RequestMethod } from '../../../services/api/models';
import { SendEmailReqBody } from '../../../../mailer/models';
import dotenv from 'dotenv';
import { Observable } from 'rxjs';
dotenv.config();


const config: RequestInit = {
	mode: 'cors',
	cache: 'no-cache',
	headers: {
		'Content-Type': 'application/json'
	},
}

const port: number = process.env.REACT_APP_API_PORT ? +process.env.REACT_APP_API_PORT : 5000;

export const MailService = {
	sendMailObs: (content: SendEmailReqBody): Observable<string> => (
		new Observable<string>( (subscriber) => {
			fetch(`/sendMail`, {
					...config,
				method: RequestMethod.POST,
				body: JSON.stringify(content),
			})
				.then((response: Response) => {
					// NOTE: we have to verify if correct status has been returned
					if(response.ok) {
						// NOTE: we have to verify if request has correct body
						return response.json()
							.then((jsonResponse) => jsonResponse)
							.catch(() => ({}))
					} else {
						subscriber.error(`Wrong response status: ${response.status}`);
						subscriber.complete();
					}
				})
				.then((responseJson) => {
					subscriber.next(responseJson);
					subscriber.complete();
				})
				.catch((error) => {
					subscriber.error(error);
					subscriber.complete();
				})
		})
	)
};
