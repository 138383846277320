// TODO: implement multi language
import { Artwork, ShowroomArtworkSign } from '../services/models/api-models';

export function getArtworkSignStatus(sign: ShowroomArtworkSign ): string {
	let status: string = '';
	if(sign.isSigned) {
		if(!sign.byHand && !sign.signedInThePlate) {
			status += 'Signed.'
		}

		if(sign.byHand) {
			status += 'Signed by hand.';
		}

		if(sign.signedInThePlate){
			status += 'Signed in the plate.';
		}

		if(sign.engraved) {
			status += 'Engraved.';
		}

		if(sign.stamped) {
			status += 'Stamped.'
		}

	}
	return status.split('.').join('. ');
}
