import React from 'react';
import { Props } from './models/props.model';
import { Style } from './styles';
import { missingArtworkIndicator } from '../../../components/generic';
import { ViewState } from '../../../models';
import { Loader } from '../../../components/loader';

export class SquareImageBoxSmall extends React.Component<
	Props,
	{ image: Style; viewState: ViewState }
> {
	imageErrored: boolean = false;

	constructor(props: Props) {
		super(props);

		this.state = {
			image: new Style(missingArtworkIndicator, props.boxSideSize),
			viewState: ViewState.Loading,
		};

		if (props.imageSrc) {
			const image = new Image();
			image.src = `${props.imageSrc}` as string;

			image.onload = () => {
				this.setState({
					image: new Style(props.imageSrc, props.boxSideSize),
					viewState: ViewState.Ok,
				});
			};

			image.onerror = () => {
				this.imageErrored = true;

				this.setState({
					viewState: ViewState.Error,
					image: new Style(missingArtworkIndicator, props.boxSideSize),
				});
			};
		} else {
			this.setState({
				viewState: ViewState.Error,
				image: new Style(missingArtworkIndicator, props.boxSideSize),
			});
		}
	}

	componentDidUpdate(
		prevProps: Props,
		prevState: { image: Style; viewState: ViewState }
	) {
		if (
			JSON.stringify(prevState) !== JSON.stringify(this.state) ||
			prevProps.boxSideSize !== this.props.boxSideSize
		) {
			this.setState({
				image: new Style(
					!this.imageErrored ? this.props.imageSrc : missingArtworkIndicator,
					this.props.boxSideSize
				),
				viewState: !this.imageErrored ? ViewState.Ok : ViewState.Error,
			});
		}
	}

	render() {
		return this.state.viewState !== ViewState.Loading ? (
			<div style={this.state.image}></div>
		) : (
			<div
				style={{
					height: this.props.boxSideSize,
					width: this.props.boxSideSize,
				}}>
				<Loader size="small" centered={true} fullSize={true} />
			</div>
		);
	}
}
