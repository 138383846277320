import React from 'react';
import { componentStyle as cs } from './styles';
import { Props } from './models';
import { ArtworkCreation } from '../../../../../services/models/api-models';
import {
	ArtworkTitle,
	ArtworkCreationYear,
	ArtworkDetailsWrapper,
} from '../../large-landscape/styles';
import {
	getArtworkSignStatus,
	getArtworkDimensions,
	getArtworkAuthors,
	getArtworkTitles,
	getArtworkTags,
	formatArtworkCreationDate,
	getArtworkEditionNumber,
} from '../../../../../utils';
import { ArtworkMeasurements } from '../artwork-measurements/ArtworkMeasurements';

export function ArtworkDetails(props: Props) {
	function getCreationString(creation: ArtworkCreation): string | null {
		if (
			!creation ||
			!creation.date ||
			!creation.date.start ||
			!creation.date.start.value ||
			!creation.date.start.format
		) {
			return null;
		}

		return `, ${formatArtworkCreationDate(creation.date, 'YYYY', false)}`;
	}

	const artworkEdition = getArtworkEditionNumber(props.artwork);

	return (
		<div style={cs.wrapper}>
			<div
				style={{
					fontWeight: 'bold',
					fontSize: '1.9rem',
					paddingBottom: '0.5rem',
				}}>
				{getArtworkAuthors(props.artwork)}
			</div>

			<ArtworkDetailsWrapper>
				<ArtworkTitle>
					{getArtworkTitles(props.artwork)}
					<ArtworkCreationYear>
						{getCreationString(props.artwork.creation)}
					</ArtworkCreationYear>
				</ArtworkTitle>
				<div style={cs.tagsContainer}>{getArtworkTags(props.artwork)}</div>
				<div>{getArtworkSignStatus(props.artwork.sign)}</div>
				{props.artwork.sign.notes && <div>{props.artwork.sign.notes}</div>}
				<ArtworkMeasurements artwork={props.artwork} />
				<div>
					{artworkEdition}
					{props.artwork.edition && props.artwork.edition.isArtistProof
						? artworkEdition
							? ". It's an artist's proof."
							: "It's an artist's proof."
						: null}
				</div>

				<div>
					{props.artwork.edition &&
						props.artwork.edition.notes &&
						props.artwork.edition.notes.length !== 0
						? props.artwork.edition.notes
						: null}
				</div>
			</ArtworkDetailsWrapper>
		</div>
	);
}
