import React from 'react';
import { DimensionElement } from '../../../models/showroom/showroom-artwork/data-section';
import { Languages } from '../../../models/globals';
import { isConversionPossible } from './is-conversion-possible';
import { convertToOtherSystem } from './convert-to-other-system';
import { ImperialUnitLabelEn, MetricUnitLabelEn } from '../models';

const x: JSX.Element = (<span style={{ color: '#7F8283' }}>x</span>);

export function createFormattedDimensions(dimensions: DimensionElement | null): JSX.Element | null {
	if(dimensions) {
		// NOTE: that conversion here is much simplified that in c-2 because always we have one unit for dimensions group
		const conversionPossible: boolean = isConversionPossible(dimensions.unit);
		// @ts-ignore
		const conversionUnit: MetricUnitLabelEn | ImperialUnitLabelEn = Object.values(MetricUnitLabelEn).includes(dimensions.unit.labels[Languages.en]) ? ImperialUnitLabelEn.in : MetricUnitLabelEn.cm;
		let dimensionsLabel: string = '';

		if(dimensions.height && dimensions.width && dimensions.depth) {
			dimensionsLabel ='(H x W x D)';
			return (
				<div>
					<p>{dimensions.height} {x} {dimensions.width} {x} {dimensions.depth} {dimensions.unit.labels[Languages.en]}</p>
					{conversionPossible && (
						<p>{convertToOtherSystem(dimensions.height, dimensions.unit, conversionUnit)} {x} {convertToOtherSystem(dimensions.width, dimensions.unit, conversionUnit)} {x} {convertToOtherSystem(dimensions.depth, dimensions.unit, conversionUnit)} {conversionUnit}</p>
					)}
					<p style={{ color: '#7F8283' }}>{dimensionsLabel}</p>
				</div>
			);
		}

		if(dimensions.height && dimensions.width) {
			dimensionsLabel = '(H x W)';
			return (
				<div>
					<p>{dimensions.height} {x} {dimensions.width} {dimensions.unit.labels[Languages.en]}</p>
					{conversionPossible && (
						<p>{convertToOtherSystem(dimensions.height, dimensions.unit, conversionUnit)} {x} {convertToOtherSystem(dimensions.width, dimensions.unit, conversionUnit)} {conversionUnit}</p>
					)}
					<p style={{ color: '#7F8283' }}>{dimensionsLabel}</p>
				</div>
			);
		}

		if(dimensions.height && dimensions.depth) {
			dimensionsLabel = '(H x D)';
			return (
				<div>
					<p>{dimensions.height} {x} {dimensions.depth} {dimensions.unit.labels[Languages.en]}</p>
					{conversionPossible && (
						<p>{convertToOtherSystem(dimensions.height, dimensions.unit, conversionUnit)} {x} {convertToOtherSystem(dimensions.depth, dimensions.unit, conversionUnit)} {conversionUnit}</p>
					)}
					<p style={{ color: '#7F8283' }}>{dimensionsLabel}</p>
				</div>
			);
		}

		if(dimensions.width && dimensions.depth) {
			dimensionsLabel = '(W x D)';
			return (
				<div>
					<p>{dimensions.width} {x} {dimensions.depth} {dimensions.unit.labels[Languages.en]}</p>
					{conversionPossible && (
						<p>{convertToOtherSystem(dimensions.width, dimensions.unit, conversionUnit)} {x} {convertToOtherSystem(dimensions.depth, dimensions.unit, conversionUnit)} {conversionUnit}</p>
					)}
					<p style={{ color: '#7F8283' }}>{dimensionsLabel}</p>
				</div>
			);
		}

		if(dimensions.height) {
			dimensionsLabel = '(H)';
			return (
				<div>
					<p>{dimensions.height} {dimensions.unit.labels[Languages.en]}</p>
					{conversionPossible && (
						<p>{convertToOtherSystem(dimensions.height, dimensions.unit, conversionUnit)} {conversionUnit}</p>
					)}
					<p style={{ color: '#7F8283' }}>{dimensionsLabel}</p>
				</div>
			);
		}

		if(dimensions.width) {
			dimensionsLabel = '(W)';
			return (
				<div>
					<p>{dimensions.width} {dimensions.unit.labels[Languages.en]}</p>
					{conversionPossible && (
						<p>{convertToOtherSystem(dimensions.width, dimensions.unit, conversionUnit)} {conversionUnit}</p>
					)}
					<p style={{ color: '#7F8283' }}>{dimensionsLabel}</p>
				</div>
			);
		}

		if(dimensions.depth) {
			dimensionsLabel = '(D)';
			return (
				<div>
					<p>{dimensions.depth} {dimensions.unit.labels[Languages.en]}</p>
					{conversionPossible && (
						<p>{convertToOtherSystem(dimensions.depth, dimensions.unit, conversionUnit)} {conversionUnit}</p>
					)}
					<p style={{ color: '#7F8283' }}>{dimensionsLabel}</p>
				</div>
			);
		}
	}

	return null;
}
