export const cookieService = {
	isCookieEnabled: function () {
		return navigator.cookieEnabled;
	},
	initializeCookie: function () {
		if (!this.isCookieEnabled) {
			return;
		}

		document.cookie = 'max-age=300';
	},
	setToken: function (token: string) {
		if (!this.isCookieEnabled) {
			return;
		}

		document.cookie = `token=${token}`;
	},
	getToken: function (): string | null {
		const tokenElement = document.cookie
			.split(';')
			.find((element) => element.split('=')[0].trim() === 'token');

		return tokenElement ? tokenElement.split('=')[1] : null;
	},
	getCookie: function () {
		return document.cookie;
	},
};
