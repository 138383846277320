import React from 'react';
import { withRouter } from 'react-router-dom';
import { MaxTextLinesBox } from '../../../../../ads-components/boxes/max-text-lines-box/MaxTextLinesBox';
import { SquareImageBox } from '../../../../../ads-components/boxes/square-image-box/SquareImageBox';
import { SquareImageBoxSmall } from '../../../../../ads-components/boxes/square-image-box/SquareImageBoxSmall';
import { HoverableDefaultRoundBorderButton } from '../../../../../ads-components/buttons/round-border-button/default/hoverable/HoverableDefaultRoundBorderButton';
import { AppContext } from '../../../../../context/context';
import { AlbumViewModel, WithRouterProps } from '../../../../../models';
import { getExpirationDate } from '../../../../../utils';
import { ExpirationIndicator, PostIndicator } from '../../../../generic';
import { IconsStatusBarContainer } from '../../../../generic/icon-status-bar-container/IconStatusBarContainer';
import { goToArtworkDetails } from '../../../../home/utils';
import { goToAlbumPage } from '../../utils/go-to-album-page';
import { MiddleContainerTitle, StyledSeparator, MiddleContainerDescriptionTablet } from '../large-landscape/styles';
import { ComponentStyleModel } from './models';
import { ButtonContainer, ComponentStyle, NoArtworksIndicator, OneImageContainer, SquareImageContainer, TwoImagesContainer } from './styles';
import { getHeaderSpacing } from './utils';


const numberOfAlbumSnapshotColumns = 2;

type State = {
	componentStyle: ComponentStyleModel;
	albumSnapshotSize: string;
	headerContainerWidth: number;
};

const albumItemsContainer = {
	itemSpacing: '3vh',
	numberOfColumns: 2,
	numberOfRows: 2,
};


interface Props extends AlbumViewModel, WithRouterProps { }

class ListItemMediumPortraitBase extends React.Component<Props, State> {
	static contextType = AppContext;

	containerRef = React.createRef<HTMLDivElement>();

	updateState = () => {
		const containerWidth = this.containerRef.current!.offsetWidth;

		const currentHeaderSpacing = getHeaderSpacing(this.context.viewportType);

		const albumSpacingCombined =
			(numberOfAlbumSnapshotColumns + 1) *
			((this.context.dimensions.height / 100) * currentHeaderSpacing);

		const albumSnapshotSize = `${(containerWidth / 2 - albumSpacingCombined + 100) / numberOfAlbumSnapshotColumns
			}px`;

		this.setState({
			componentStyle: new ComponentStyle(
				`${containerWidth / 2} px`,
				currentHeaderSpacing + 'vh'
			),
			albumSnapshotSize,
			headerContainerWidth: containerWidth,
		});
	};

	componentDidMount() {
		this.updateState();
	}

	componentDidUpdate() {
		this.containerRef.current!.offsetWidth !==
			this.state.headerContainerWidth && this.updateState();
	}

	render() {
		if (!this.state || !this.state.componentStyle) {
			return <div ref={this.containerRef}></div>;
		}

		let previews = this.props.previews.map((preview, index) => {
			return (
				<div
					onClick={() =>
						goToArtworkDetails(
							this.props.history,
							this.props.uuid,
							preview.artworkId
						)
					}
					key={index}>
					<SquareImageContainer>
						<SquareImageBoxSmall
							imageSrc={preview.url}
							boxSideSize={'23.5vw'}></SquareImageBoxSmall>
					</SquareImageContainer>
				</div>
			);
		});

		normalizeAlbumPreviews(
			previews,
			albumItemsContainer.numberOfRows * albumItemsContainer.numberOfColumns
		);
		return (
			<div
				id={`list - item - ${this.props.id} `}
				key={this.props.id}
				ref={this.containerRef}
				style={this.state.componentStyle.container}>
				<div style={this.state.componentStyle.header}>
					{previews.length == 1 ?
						<OneImageContainer style={{ paddingRight: '0.1rem' }}
							onClick={() => goToAlbumPage(this.props.history, this.props.uuid)}>
							<SquareImageBox
								imageSrc={this.props.coverImageSrc}
								boxSideSize={
									'100%'
								}></SquareImageBox>
						</OneImageContainer>
						:
						previews.length == 2 ?
							<TwoImagesContainer style={{ paddingRight: '0.1rem' }}
								onClick={() => goToAlbumPage(this.props.history, this.props.uuid)}>
								<SquareImageBox
									imageSrc={this.props.coverImageSrc}
									boxSideSize={
										'100%'
									}></SquareImageBox>
							</TwoImagesContainer>
							:
							<SquareImageContainer style={{ paddingRight: '0.1rem' }}
								onClick={() => goToAlbumPage(this.props.history, this.props.uuid)}>
								<SquareImageBox
									imageSrc={this.props.coverImageSrc}
									boxSideSize={
										'100%'
									}></SquareImageBox>
							</SquareImageContainer>
					}

					{previews.length >= 3 ? (
						<div style={this.state.componentStyle.albumItems}>{previews}</div>
					) : previews.length == 2 ? (
						<div style={this.state.componentStyle.albumTwoItems}>{previews}</div>
					) : previews.length == 1 ? (
						''
					) : <NoArtworksIndicator><span>No artworks</span></NoArtworksIndicator>
					}
				</div>
				<div>
					<div style={this.state.componentStyle.content}>
						{this.props.postedOn ? (
							<div style={this.state.componentStyle.infoBar}>
								<PostIndicator postedOn={this.props.postedOn} />
								{this.props.expiresOn && (<StyledSeparator> | </StyledSeparator>)}
								<ExpirationIndicator expiresOn={this.props.expiresOn} />
							</div>
						) : null}

						<MiddleContainerTitle
							onClick={() => goToAlbumPage(this.props.history, this.props.uuid)}>
							{this.props.title}
						</MiddleContainerTitle>

						<MiddleContainerDescriptionTablet>
							<MaxTextLinesBox maxLinesCount={5} showReadMoreIndicator={false}>
								<p>{this.props.description}</p>
							</MaxTextLinesBox>
						</MiddleContainerDescriptionTablet>

						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div style={this.state.componentStyle.iconsBar}>
								<IconsStatusBarContainer
									albumUuid={this.props.uuid}
									likes={this.props.likes}
									timesVisited={this.props.timesVisited}
									artworksCount={this.props.artworksCount}
								/>
							</div>
							<ButtonContainer
								className="hoverable-button">
								<HoverableDefaultRoundBorderButton
									onClick={() => {
										goToAlbumPage(this.props.history, this.props.uuid);
									}}
									text="View Showroom"></HoverableDefaultRoundBorderButton>
							</ButtonContainer>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export const ListItemMediumPortrait = withRouter(ListItemMediumPortraitBase);
function normalizeAlbumPreviews(
	previews: JSX.Element[],
	maxPreviews: number
): JSX.Element[] {
	if (previews.length > maxPreviews) {
		previews.length = maxPreviews;

		return previews;
	}

	return previews;
}


