import { ViewportType } from '../../../../../../context/models';

// NOTE: Return value should refer to vh
export function getHeaderSpacing(viewportType: ViewportType): number {
	if (viewportType === +ViewportType.SmallPortrait) {
		return 1.5;
	} else if (viewportType === +ViewportType.SmallLandscape) {
		return 4;
	} else if (viewportType === +ViewportType.MediumPortrait) {
		return 3;
	} else if (viewportType === +ViewportType.MediumLandscape) {
		return 3;
	} else {
		return 11;
	}
}
