import { Effect } from '../models';

export class ComponentStyle {
	width: string;
	opacity: string;

	constructor(width: string, opacity: string) {
		this.width = width;
		this.opacity = opacity;
	}
}
