export enum TextAlign {
	Center = 'center',
	MozInitial = '-moz-initial',
	Inherit = 'inherit',
	Initial = 'initial',
	Revert = 'revert',
	Unset = 'unset',
	End = 'end',
	Start = 'start',
	Left = 'left',
	Right = 'right',
	Justify = 'justify',
	MatchParent = 'match-parent',
}
