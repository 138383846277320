import React from 'react';
import { AppContext, defaultAppState } from './context';
import { AppContext as AppContextModel } from './models';
import { getViewportType } from './utils/get-viewport-type';
import { ApiService } from '../services/api.service';

export class AppContextProvider extends React.Component<any, AppContextModel> {
	constructor(props: any) {
		super(props);

		this.state = defaultAppState;

		//NOTE: Check for user profile
		const apiService = new ApiService();

		apiService.getProfile().subscribe(
			(userProfile) => {},
			(error) => {}
		);

		window.addEventListener('resize', (event) => {
			const width = (event.srcElement as any).innerWidth;
			const height = (event.srcElement as any).innerHeight;

			this.setState({
				...this.state,
				viewportType: getViewportType(width, height),
				dimensions: {
					width,
					height,
				},
			});
		});
	}

	render() {
		return (
			<AppContext.Provider value={this.state}>
				{this.props.children}
			</AppContext.Provider>
		);
	}
}
