import React from 'react';
import { getPostedDate } from '../../../utils';
import { componentStyle as cs } from '../expiration-indicator/styles';
import { PostIndicatorContainer } from './styles';

interface Props {
	postedOn: string | null;
}

export function PostIndicator(props: Props) {
	const indicator = props.postedOn ? (
		<PostIndicatorContainer style={cs.wrapper}>
			<span>{getPostedDate(props.postedOn as string)}</span>
		</PostIndicatorContainer>
	) : null;

	return indicator;
}
