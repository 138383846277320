import React, { useState } from 'react';
import { useEffect } from 'react';
import { actionStyle } from '../../../components/top-bar/styles';
import { Props, Tab } from './models';
import {
	TabHeaderStyle,
	TabMobile,
	TabMobileContainer,
	TabsContainer,
} from './styles';

export function TabbedTextBox(props: Props) {
	const [activeTabId, setActiveTabId] = React.useState(props.defaultTabId);
	const [activeTabIds, setActiveTabIds] = useState<string[]>([
		props.defaultTabId,
	]);

	const tabHeaders = props.tabs.map((tab) => {
		return (
			<h1
				key={tab.id}
				onClick={() => setActiveTabId(tab.id)}
				style={new TabHeaderStyle(tab.id === activeTabId)}>
				{tab.title}
			</h1>
		);
	});

	const toggle = (tabId: string) => {
		const exist = activeTabIds.includes(tabId);
		if (exist) {
			setActiveTabIds(activeTabIds.filter((id) => id !== tabId));
		} else {
			setActiveTabIds([...activeTabIds, tabId]);
		}
	};

	const mobileTabs = props.tabs.map((tab) => {
		return (
			<>
				<TabMobileContainer>
					<TabMobile
						key={tab.id}
						onClick={() => toggle(tab.id)}
						className={typeof tab.content === 'string' ? 'active_tab' : 'unactive_tab'}>
						<h2>{tab.title}</h2>
						<div className={activeTabIds.includes(tab.id) ? 'arrow_up' : 'arrow_down'}>
							<img
								src="/icons/arrow-down.svg"
								className={
									typeof tab.content === 'string' ? '' : 'no_arrow'
								}></img>
						</div>
					</TabMobile>
				</TabMobileContainer>
				{activeTabIds.includes(tab.id) && !(typeof tab.content !== 'string') ? (
					<div
						id="content"
						style={{ width: '100%', margin: '2rem 0', fontSize: '1.6rem' }}
						dangerouslySetInnerHTML={{ __html: tab.content as string }}
					/>
				) : null}
			</>
		);
	});

	const activeTab: Tab<any> | undefined = props.tabs.find(
		(tab: Tab<any>) => tab.id === activeTabId
	);

	function getView(tab: Tab<any> | undefined) {
		if (tab) {
			if (typeof tab.content === 'string') {
				return (
					<div
						id="content"
						style={{ width: '100%', marginTop: '2rem' }}
						dangerouslySetInnerHTML={{ __html: tab.content as string }}
					/>
				);
			} else {
				return (
					<div id="content" style={{ width: '100%', marginTop: '2rem' }}>
						{tab.content}
					</div>
				);
			}
		}
	}

	// @ts-ignore
	return (
		<TabsContainer>
			<div id="container">
				<div id="tabs-container" style={{ display: 'flex' }}>
					{tabHeaders}
				</div>
				{getView(activeTab)}
			</div>

			<div id="container-mobile" style={{ borderBottom: '1px solid #F6F6F6' }}>
				<div id="tabs-container">{mobileTabs}</div>
			</div>
		</TabsContainer>
	);
}
