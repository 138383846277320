import styled from 'styled-components';
import Breakpoints from '../../../../../styles/Breakpoints';

const HoverableButtonContainer = styled.div`
	@media all and (max-width: ${Breakpoints.phone}) {
		height: 4.2rem;
	}
`;

export { HoverableButtonContainer };
