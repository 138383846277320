import styled from 'styled-components';
import Breakpoints from '../../../../../../../../styles/Breakpoints';

const EmptyStateContainer = styled.span`
	color: #959699;
	font-style: italic;
	font-size: 1.4rem;
`;

const StyledComponent = styled.div`
	.not_expanded {
		@media all and (max-width: 766px) {
			display: none;
		}
	}

	.expanded {
		@media all and (min-width: ${Breakpoints.phone}) {
			display: none;
		}
	}
`;

export { EmptyStateContainer, StyledComponent };
