export function cropAlbumName(albumName: string, maxChars: number) {
	// NOTE: TopBar album name should not be longer than 27 characters for now
	if (albumName.length > maxChars) {
		const splitted = albumName.split(' ');

		if (splitted.length > 1) {
			return (
				splitted.reduce((acc, word) => {
					if ((acc + word).length < maxChars) {
						return `${acc}  ${word}`;
					}

					return acc;
				}, '') + '...'
			);
		} else {
			return albumName.slice(0, maxChars) + '...';
		}
	}

	return albumName;
}
