import styled from 'styled-components';
import {
	AlignItems,
	BoxSizing,
	Cursor,
	Display,
	JustifyContent,
} from '../../../ads-components/models';
import { textColor } from '../../../styles';
import Breakpoints from '../../../styles/Breakpoints';

export const componentStyle = {
	wrapper: {
		padding: '0 6vw',
		width: '100%',
		boxSizing: BoxSizing.BorderBox,
	},
	actions: {
		width: '100%',
		display: Display.Flex,
		justifyContent: JustifyContent.SpaceBetween,
		alignItems: AlignItems.Baseline,
		paddingLeft: '2vw',
	},
	navigationButton: {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		cursor: Cursor.Pointer,
	},
	arrow: {
		marginRight: '0.5rem',
	},
	text: {
		fontSize: '1.5rem',
	},
};

const TextButtonContainer = styled.div`
	margin: 2rem 2.4rem 0 0;
	& > button {
		border-radius: 25px;
		@media all and (max-width: ${Breakpoints.phone}) {
			height: 4.2rem;
		}
	}

	& > button + button {
		margin-left: 8px;
	}
`;

const ButtonText = styled.span`
	color: ${textColor};
	font-size: 1.3rem;
`;

const ToolbarActions = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 2vw;

	@media all and (max-width: ${Breakpoints.tablet}) {
		width: auto;

		#top-bar-portal {
			position: absolute;
			left: 0;
			top: 2rem;
		}
	}
`;

const ToolbarMobileActions = styled.div`
	position: absolute;
	right: 1.5rem;
	@media all and (min-width: ${Breakpoints.tablet}) {
		display: none;
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
	@media all and (max-width: ${Breakpoints.tablet}) {
		display: none;
	}
`;

const ToolbarWrapperContainer = styled.div`
	min-height: 8rem;
	@media all and (min-width: ${Breakpoints.tablet}) {
		box-shadow: rgb(0 0 0 / 5%) 0px 2px 2px;
	}

	@media all and (max-width: ${Breakpoints.tablet}) {
		border-bottom: 2px solid #f9f9f9;
		min-height: 6.2rem;
	}

	@media all and (max-width: ${Breakpoints.phone}) {
		border-bottom: 2px solid #f9f9f9;
		min-height: 5.8rem;
		z-index: 2;
	}
`;

const TopbarWrapper = styled.div`
	:after {
		display: inline-block;
		content: '';
		width: 100%;
		height: 0.5rem;
		background: red;
	}
`;

export {
	TextButtonContainer,
	ButtonText,
	ToolbarActions,
	ToolbarMobileActions,
	ButtonsContainer,
	ToolbarWrapperContainer,
	TopbarWrapper,
};
