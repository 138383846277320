import styled from 'styled-components';
import {
	Display,
	FlexDirection,
	JustifyContent,
	BoxSizing,
	Cursor,
} from '../../../../../ads-components/models';

import { defaultBackgroundColor, textColor } from '../../../../../styles';
import Breakpoints from '../../../../../styles/Breakpoints';

const defaultOutlineColor = '#0089D4';
const defaultOutlineOffset = '3px';

export const componentStyle = {
	mainContainer: {
		display: Display.Flex,
		flex: '1',
	},
	wrapper: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		flex: '1 1 auto',
		height: '1px',
		padding: '0 6vw',
		outline: 'none',
		paddingTop: '3vh',
	},
	navigationBar: {
		marginBottom: '2vh',
		height: '4vh',
	},
	descriptionContainer: {
		marginBottom: '2vh',
	},
	imageContainer: {
		display: Display.Flex,
		justifyContent: JustifyContent.Center,
		padding: '0 5vh 5vh 4vh',
		height: '100%',
		maxWidth: 'calc(44vw - 4vh)',
		maxHeight: 'calc(75vw - 80px)',
		cursor: 'zoom-in',
	},
	leftContainer: {
		display: Display.Flex,
		flexDirection: FlexDirection.Column,
		boxSizing: BoxSizing.BorderBox,
		padding: '2vh',
		width: '50%',
		background: defaultBackgroundColor,
	},
	rightContainer: {
		boxSizing: BoxSizing.BorderBox,
		paddingLeft: '4vw',
		width: '50%',
	},
	tabbedBoxContainer: {
		width: '70%',
		height: '70vh',
	},
	previewBox: function (isSelected: boolean) {
		let opacity = '1';
		let outline = 'none';
		let outlineOffset = '0';
		let cursor = Cursor.Pointer;
		let minWidth: 'auto';

		if (!isSelected) {
			opacity = '.7';
		} else {
			outline = `${defaultOutlineOffset} solid ${defaultOutlineColor}`;
			outlineOffset = `-${defaultOutlineOffset}`;
			cursor = Cursor.Default;
			minWidth = 'auto';
		}
		return {
			marginRight: '1vh',
			opacity,
			outline,
			outlineOffset,
			cursor,
			minWidth: 'auto',
		};
	},
};

const BackLinkButton = styled.div`
	span {
		border-bottom: 1px solid transparent;
		transition: 0.2s;
	}
	&:hover {
		span {
			border-color: #050e0f;
		}
	}
`;

const ArtworkTitle = styled.h1`
	font-size: 1.5rem;
	font-style: italic;

	@media all and (max-width: ${Breakpoints.tablet}) {
		white-space: normal;
		overflow: unset;
		text-overflow: unset;
	}
`;

const ArtworkCreationYear = styled.span`
	font-style: normal;
`;

const ArtworkDetailsContainer = styled.div`
	position: sticky;
	@media all and (min-width: ${Breakpoints.tablet}) {
		:before {
			display: inline-block;
			content: '';
			width: 100%;
			height: 0.5rem;
			background: #fff;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
`;

const ArtworkDetailsWrapper = styled.div`
	@media all and (max-width: ${Breakpoints.phone}) {
		font-size: 1.6rem;
	}
`;

export {
	BackLinkButton,
	ArtworkTitle,
	ArtworkCreationYear,
	ArtworkDetailsContainer,
	ArtworkDetailsWrapper,
};
