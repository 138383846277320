require('dotenv').config()

const api_endpoint = 'https://public-albums.dev.arteia.xyz';
//const api_endpoint = 'http://localhost:3001/presentation-app';
const like_api_endpoint = `${api_endpoint}/like`;

const signup_endpoint =
	'https://secure.arteia.com/sign-up?returnTo=public-albums-client&userLogin=true';
const login_endpoint =
	'https://secure.arteia.com/login?returnTo=public-albums-client&userLogin=true';

const profile_endpoint = `${api_endpoint}/profile`;



const new_showrooms_api_endpoint = process.env.REACT_APP_API_SHOWROOMS;
const showrooms_api_endpoint= process.env.REACT_APP_API_SHOWROOMS;

const api_assets_endpoint = `${showrooms_api_endpoint}/asset/image`

// TODO: use below path to fetch images
const showroom_assets_endpoint: string = showrooms_api_endpoint + '/public/showrooms/assets'

export {
	api_endpoint,
	like_api_endpoint,
	api_assets_endpoint,
	signup_endpoint,
	login_endpoint,
	profile_endpoint,
	showrooms_api_endpoint,
	new_showrooms_api_endpoint
};
