// NOTE: At this point album artworks should have 'gaps' in form of undefined elements

import { Artwork, ApiError, EncryptedArtwork } from '../models/api-models';
import { Observable } from 'rxjs';

// NOTE: Get required getAlbumArtworks calls
export function getArtworkRequestObservables(
	albumUuid: string,
	artworks: (Artwork | undefined)[],
	initialSkip: number,
	maxArtworksToLoad: number,
	observable: (
		albumUuid: string,
		skip: number,
		limit: number
	) => Observable<Artwork[] | EncryptedArtwork[] | ApiError>,
	topSentinelIndex: number | null
) {
	let skip = initialSkip;
	let limit = 0;
	let ready = 0;

	function prepareArtworks(
		artworks: (Artwork | undefined)[],
		initialSkip: number,
		topSentinelIndex: number | null
	) {
		if (initialSkip) {
			return artworks.slice(initialSkip);
		}

		if (topSentinelIndex) {
			return artworks.slice(0, topSentinelIndex).reverse();
		}

		return artworks;
	}

	const preparedArtworks = prepareArtworks(
		artworks,
		initialSkip,
		topSentinelIndex
	);

	return preparedArtworks.reduce(
		(
			acc: Observable<Artwork[] | ApiError | EncryptedArtwork[]>[],
			artwork: Artwork | undefined,
			index: number,
			artworks: (Artwork | undefined)[]
		) => {
			if (ready === maxArtworksToLoad) {
				return acc;
			}

			if (artwork) {
				if (limit > 0) {
					acc.push(observable(albumUuid, skip, limit));

					ready += limit;

					skip = skip + limit + 1;
					limit = 0;
				} else {
					skip++;
				}
			} else {
				limit++;
			}

			if (maxArtworksToLoad === index + 1 && !acc.length && !skip) {
				acc.push(observable(albumUuid, skip, maxArtworksToLoad));

				ready = maxArtworksToLoad;

				return acc;
			}

			if (artworks.length == index + 1 && limit > 0) {
				acc.push(
					observable(
						albumUuid,
						skip,
						limit > maxArtworksToLoad ? maxArtworksToLoad : limit
					)
				);
			}

			return acc;
		},
		[]
	);
}
