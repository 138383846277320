import React from 'react';
import { ViewportType } from '../../../context/models';
import { getAlbumViewModel } from '../../../utils/';
import { Album, Artwork } from '../../../services/models/api-models';
import { HomeSmallPortrait, HomeLargeLandscape } from '../views';

export function getAlbumView(
	viewportType: ViewportType,
	album: Album<Artwork> | null,
	loadMoreArtworksFn: Function,
	// NOTE: On album view model creation cover photo needs to know how to establish imageSrc.
	//		 This is due to inconsistent way of getting private album artworks (private 'imageSrc' needs to get decrypted in order to get proper image path)
	isPrivate: boolean
) {
	if (album === null) {
		return null;
	}

	const props = getAlbumViewModel(
		album as Album<Artwork>,
		true,
		loadMoreArtworksFn,
		isPrivate
	);

	if (
		viewportType === +ViewportType.SmallPortrait ||
		viewportType === +ViewportType.MediumPortrait ||
		viewportType === +ViewportType.SmallLandscape ||
		viewportType === +ViewportType.MediumLandscape
	) {
		return <HomeSmallPortrait {...props}></HomeSmallPortrait>;
	} else if (viewportType === +ViewportType.LargeLandscape) {
		return <HomeLargeLandscape {...props}></HomeLargeLandscape>;
	} else {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<h1>Home</h1>
				<p>Missing layout</p>
			</div>
		);
	}
}
