import { BackgroundRepeat } from '../../../models';

export class Style {
	backgroundImage: string;
	minWidth: string | undefined;
	height: string;
	backgroundColor: string;

	backgroundRepeat = BackgroundRepeat.NoRepeat;
	backgroundSize = 'cover';
	backgroundPosition = 'center center';
	width: string;

	constructor(backgroundImageUrl: string, sideSize: string, backgroundColor?: string) {
		this.backgroundImage = `url(${backgroundImageUrl})`;
		this.width = this.height = sideSize;
		this.backgroundColor = backgroundColor || '#F2F2F2'
	}
}
