import { Cursor } from '../../../../../ads-components/models';

export class ArtworkListElement {
	marginBottom: string;
	cursor = Cursor.Pointer;

	constructor(addMarginBottom: boolean) {
		this.marginBottom = addMarginBottom ? '4rem' : '';
	}
}
