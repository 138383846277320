import { Observable } from 'rxjs';
import { ApiService } from '../../api.service';
import { Album, Artwork } from '../api-models';

// TODO: A lot of duplication from public service. Whole service seems redundant...
export abstract class AbstractPrivateAlbumService {
	// NOTE: Same as public. Data is decrypted in getAlbumObs and loadMoreALbumArtworksObs
	protected albumsCache: Album<Artwork>[] = [];
	protected apiService = new ApiService();

	// NOTE: Specific as per public aśŚlbums.
	abstract getAlbumObs(
		albumUuid: string,
		withArtworks: boolean
	): Observable<Album<Artwork>>;
	// NOTE: Same as public.
	abstract addAlbumToCache(album: Album<Artwork>): void;
	// NOTE: Specific as per private albums..
	abstract getAlbumArtworksObs(
		albumUuid: string,
		limit: number,
		initialSkip: number,
		topSentinelIndex: number | null
	): Observable<Album<Artwork | undefined>>;
	abstract getArtworkObs(
		albumUuid: string,
		artworkId: string
	): Observable<[Album<Artwork>, Artwork]>;
}
