import React, { useContext, useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { ViewState } from '../../../../models';
import { AppContext } from '../../../../context/context';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Album, Artwork } from '../../../../services/models/api-models';
import { encodePassword } from './utils';
import {
	componentStyle as cs,
	InfoText,
	InputContainer,
	LogoImage, PrivateLoginContainer, RightsContainer,
	SubmitButtonContainer,
	InfoBoxContainer
} from '../login/styles';
import { arteiaLogoShowroomsSrc } from '../../../generic';
import { LabeledInput } from 'ads';
import { Background } from 'ads/dist/src/components/models';

interface LocationState {
	returnPath: string | undefined,
}

export function PrivateShowroomLogin(): JSX.Element {
	const context = useContext(AppContext);
	const componentSubs: Subscription = new Subscription();
	const [viewState, setViewState] = useState<ViewState>(ViewState.Loading);
	const [password, setPassword] = useState<string>('');
	const [passwordError, setPasswordError] = useState<string | null>(null);
	const params = useParams<{ uuid: string }>();
	const location = useLocation<LocationState>();
	const history = useHistory();

	useEffect(() => () => componentSubs.unsubscribe(), []);
	useEffect(() => {
		if (params.uuid) {
			verifyExistence();
		}
	}, [params.uuid]);

	function verifyExistence(): void {
		setViewState(ViewState.Loading);
		componentSubs.add(
			context.showroomService.privateShowroomExistsObs(params.uuid).subscribe(
				() => { setViewState(ViewState.Ok); },
				() => { history.push('/private/404'); }
			)
		)
	}

	function handleSubmit(): void {
		try {
			const encodedPassword = encodePassword(password);
			setViewState(ViewState.Loading);
			componentSubs.add(
				context.showroomService.getPrivateShowroomObs(params.uuid, encodedPassword)
					.subscribe(
						(showroom: Album<Artwork>) => {
							if (location.state && location.state.returnPath) {
								history.push(location.state.returnPath, { showroom });
							} else {
								history.push(`/private/${params.uuid}`, { showroom });
							}
						},
						(error) => {
							setViewState(ViewState.Ok);
							setPasswordError("Sorry, that password isn't right");
						}
					)
			)
		} catch (e) {
			console.error(e);
		}
	}
	const currentYear: number = new Date().getFullYear();

	return (
		<PrivateLoginContainer style={cs.wrapper}>
			<LogoImage>
				<img src={arteiaLogoShowroomsSrc} onClick={() => history.push('/')} />
			</LogoImage>
			<InfoBoxContainer style={cs.infoBox}>
				<div>
					<InfoText style={cs.infoText}>
						<span>
							Enter password to access<br />the showroom
						</span>
					</InfoText>
					<InputContainer>
						<LabeledInput
							label='Password'
							name="password"
							type="password"
							onChange={(event) => {
								if (passwordError !== null) {
									setPasswordError(null);
								}
								setPassword(event.target.value);
							}}
							//@ts-ignore
							onKeyPress={(event) => {
								if (event.key === 'Enter') {
									handleSubmit();
								}
							}}
							error={passwordError !== null}
							errorMessage={passwordError}
							value={password}
							background={Background.DARK}
						/>
					</InputContainer>
					<SubmitButtonContainer>
						<div style={cs.submitButton} onClick={() => handleSubmit()}>
							<span style={{ marginRight: '0.5rem' }}>Submit</span>{' '}
						</div>
					</SubmitButtonContainer>
				</div>
			</InfoBoxContainer>
			<RightsContainer>
				<span>
					@ {currentYear} Arteïa - All rights reserved
				</span>
			</RightsContainer>
		</PrivateLoginContainer>
	);
}
