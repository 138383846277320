import React from 'react';


export function SoldIndicator(): JSX.Element {
	return <div style={{
		textTransform: 'uppercase',
		backgroundColor: "#FDF4F6",
		color: "#CD3356",
		display: 'inline-block',
		padding: '4px 8px',
		marginTop: '2rem',
	}}>sold</div>
}
