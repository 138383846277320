import React, { SyntheticEvent } from 'react';
import { withRouter } from 'react-router-dom';
import Viewer from 'viewerjs';
import { Measurement } from '../../../../ads-components/boxes/static-image-box/models';
import { RowHorizontalContainer } from '../../../../ads-components/containers/grids/rows/RowHorizontalContainer';
import { AppContext } from '../../../../context/context';
import { getImageAssetUrl } from '../../../../utils';
import { ViewActionsPortal } from '../../../generic/view-actions-portal/ViewActionsPortal';
import { actionStyle } from '../../../top-bar/styles';
import { Props, State } from '../../models';
import { getInitialState, updateDetailsState } from '../../utils';
import { ArtworkDetails } from '../generic/artwork-details/ArtworkDetails';
import { ArtworkMeasurements } from '../generic/artwork-measurements/ArtworkMeasurements';
import { ArtworkDetailsTabs } from '../generic/artworks-details-tabs';
import { NavigationBar } from '../generic/navigation-bar/NavigationBar';
import { SalesDetails } from '../generic/sales-details';
import {
	ArtworkDetailsContainer,
	BackLinkButton,
	componentStyle as cs,
} from './styles';
import './styles/viewer.css';

const previewImagesRowHeight = '10vh';

class DetailsLargeLandscapeBase extends React.Component<Props, State> {

	static contextType = AppContext;
	viewer: Viewer | null = null;

	previewImageSrc =
		this.props.artwork.previews[this.props.previewIndex] &&
		this.props.artwork.previews[this.props.previewIndex].small;
	wrapperRef = React.createRef<HTMLDivElement>();

	onKeyDown = (event: SyntheticEvent<any, KeyboardEvent>) => {
		const keyCode = event.nativeEvent.keyCode;

		if (keyCode === 39) {
			this.props.changeArtworkFn('next', false, 0);
		} else if (keyCode === 37) {
			this.props.changeArtworkFn('previous', false, 0);
		}
	};

	constructor(props: Props) {
		super(props);

		this.state = getInitialState();
	}

	componentDidMount() {
		// NOTE: Used to enable keypress toggling after view loads

		updateDetailsState.call(
			this,
			cs,
			previewImagesRowHeight,
			Measurement.Height
		);

		const element = document.getElementById('viewerjs-previews');

		if (!element) {
			throw Error('Unable to find viewerjs previews element');
		}

		this.viewer = new Viewer(element!, {
			inline: false,
			backdrop: false,
		});
	}

	componentDidUpdate(prevProps: Props) {
		if (
			prevProps.previewIndex !== this.props.previewIndex ||
			prevProps.artwork._id !== this.props.artwork._id
		) {
			updateDetailsState.call(
				this,
				cs,
				previewImagesRowHeight,
				Measurement.Height
			);
		}
		const element = document.getElementById('viewerjs-previews');

		if (element) {
			if (this.viewer !== null) {
				this.viewer.destroy();
				this.viewer = new Viewer(element!, {
					inline: false,
					backdrop: false,
				});
			}
		}

		if (this.viewer) {
			this.viewer.destroy();
			this.viewer.update();
			this.viewer.moveTo(0);
		}
	}

	openImageViewer() {
		if (this.viewer) {
			this.viewer.show();
		}
	}

	render() {
		return (
			<>
				<ArtworkDetailsContainer
					ref={this.wrapperRef}
					style={cs.wrapper}
					onKeyDown={(e) => this.onKeyDown(e)}
					tabIndex={0}>
					<div style={cs.navigationBar}>
						<NavigationBar
							albumArtworkCount={this.props.albumArtworkCount}
							artworkIndex={this.props.artwork.artworkOffset}
							changeArtwork={this.props.changeArtworkFn}
						/>
					</div>

					<div style={cs.mainContainer}>
						<div style={cs.leftContainer}>
							<div
								style={{
									...cs.imageContainer,
									padding: this.state.previews
										? cs.imageContainer.padding
										: '5vh',
									cursor: this.state.previews ? 'zoom-in' : '',

									pointerEvents: this.state.previews ? 'all' : 'none',
								}}
								onClick={() => this.openImageViewer()}>
								{this.state.preview}
							</div>
							{this.state.previews ? (
								<div style={{ height: previewImagesRowHeight }}>
									<RowHorizontalContainer
										numberOfRows={1}
										rowHeight={previewImagesRowHeight}>
										{this.state.previews}
									</RowHorizontalContainer>
								</div>
							) : null}
						</div>

						<ul
							id="viewerjs-previews"
							style={{ height: '0px', width: '0px', overflow: 'hidden' }}>
							{(this.props.artwork.previews || []).map((preview) => (
								<li>
									<img src={getImageAssetUrl(preview.assetId)} />
								</li>
							))}
						</ul>

						<div style={cs.rightContainer}>
							<ArtworkDetails artwork={this.props.artwork} />
							<SalesDetails artwork={this.props.artwork} />
							<ArtworkDetailsTabs artwork={this.props.artwork} />
						</div>
					</div>
				</ArtworkDetailsContainer>
				<ViewActionsPortal>
					<BackLinkButton
						onClick={() => {
							if (this.props.private) {
								this.props.history.push(
									`/private/${this.props.artwork.albumUuid}/artworks`,
									{ ...this.props.history.location.state }
								);
							} else {
								this.props.history.push(
									`/public/${this.props.artwork.albumUuid}/artworks`
								);
							}
						}}
						style={actionStyle.navigationButton}>
						<img
							style={actionStyle.arrow}
							src="/icons/ico-showroom-arrow-left.svg"
						/>
						<span style={actionStyle.text}>{this.props.albumName}</span>
					</BackLinkButton>
				</ViewActionsPortal>
			</>
		);
	}
}

export const DetailsLargeLandscape = withRouter(DetailsLargeLandscapeBase);
