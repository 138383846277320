import { Labels, Languages } from '../../../models/globals';
import { ImperialUnitLabelEn, MetricUnitLabelEn } from '../models';

export function isConversionPossible(unit: {
	id: string,
	labels: Labels,
}): boolean {
	return (
		Object.values(MetricUnitLabelEn).includes(unit.labels[Languages.en] as MetricUnitLabelEn)
		|| Object.values(ImperialUnitLabelEn).includes(unit.labels[Languages.en] as ImperialUnitLabelEn)
	);
}
