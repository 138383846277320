import React from 'react';
import { artworkContaineMeasurements } from '../../../utils';
import { componentStyle as cs } from './styles';
import { Props } from './models';
import { textColor } from '../../../../../styles';
import { createFormattedDimensions } from '../../../../../services/units';

export function ArtworkMeasurements(props: Props) {
	if(props.artwork.dimensions) {
		const framedDimensionsElement: JSX.Element | null = createFormattedDimensions(props.artwork.dimensions.framed);
		const unframedDimensionsElement: JSX.Element | null = createFormattedDimensions(props.artwork.dimensions.unframed);

		// NOTE: if we have unframed dimensions its indicates that dimensions.framed should be presented as framed dimensions
		// NOTE: if unframed dimensions are missing we should display dimensions.framed as dimensions
		return (
			<>
				{unframedDimensionsElement && (
					<div style={{ marginBottom: '2rem' }}>
						{framedDimensionsElement
							? (<p>Unframed dimensions: </p>)
							: (<p>Dimensions: </p>)
						}
						{unframedDimensionsElement}
					</div>
				)}
				{framedDimensionsElement && (
					<div style={{ marginBottom: '2rem' }}>
						<p>Framed dimensions: </p>
						{framedDimensionsElement}
					</div>
				)}
				{props.artwork.dimensions.notes && (
					<div style={{ marginBottom: '2rem' }}>
						<p>{props.artwork.dimensions.notes}</p>
					</div>
				)}
			</>
		)
	}
	return null;
}
