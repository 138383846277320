import { Observable} from 'rxjs';
import { Album, Artwork } from './models/api-models';
import { ShowroomsAPI } from './api';
import { Showroom } from '../models';
import { mapShowroomToPresentationAlbum } from './mappers';
import { ShowroomDataMock } from './temporary-fake-showroom';

// TODO: implement LIKESAPI -> next iteration

export interface GetPublicShowroomsResponse {
	albums: Album<Artwork>[],
	total: number,
}

export class ShowroomService {
	getPublicShowroomsObs = (
		pageNumber: number,
		pageSize: number,
	): Observable<GetPublicShowroomsResponse> => (
		new Observable((subscriber) => {


/*			subscriber.next({
				albums: [mapShowroomToPresentationAlbum(ShowroomDataMock)],
				total: 4,
			});
			subscriber.complete();*/

			// NOTE: below we will get reduced information about showroom artworks ( in the future )
			ShowroomsAPI.get(`/public/showrooms?paged=true&pageNumber=${pageNumber}&pageSize=${pageSize}`)
				.then((res: any) => {

					subscriber.next({
						albums: res.entries.map((showroom: Showroom) => mapShowroomToPresentationAlbum(showroom)),
						// TODO: change total when backend will be ready
						total: 20,
					});
					subscriber.complete();
				})
				.catch((error) => {
					subscriber.error(error);
					subscriber.complete();
				})
		})
	);

	getPublicShowroomObs = (
		id: string,
	): Observable<Album<Artwork>> => (
		new Observable((subscriber) => {
/*			subscriber.next(mapShowroomToPresentationAlbum(ShowroomDataMock));
			subscriber.complete();*/

			ShowroomsAPI.get(`/public/showrooms/${id}`)
				.then((showroom: Showroom) => {
					const album = mapShowroomToPresentationAlbum(showroom);

					subscriber.next(album);
					subscriber.complete()
				})
				.catch((err) => {
					subscriber.error(err);
					subscriber.complete();
				})
		})
	);

	privateShowroomExistsObs = (
		id: string,
	): Observable<boolean> => (
		new Observable<boolean>((subscriber) => {
			ShowroomsAPI.get(`/private/showrooms/${id}`)
				.then(() => {
					subscriber.next(true);
					subscriber.complete();
				})
				.catch((error) => {
					subscriber.error(error);
					subscriber.complete();
				})
		})
	)

	getPrivateShowroomObs = (
		id: string,
		password: string,
	): Observable<Album<Artwork>> => (
		new Observable((subscriber) => {
			ShowroomsAPI.post(`/private/showrooms/${id}`, {
				password
			})
				.then((showroom: Showroom) => {
					const album = mapShowroomToPresentationAlbum(showroom);

					subscriber.next(album);
					subscriber.complete()
				})
				.catch((err) => {
					subscriber.error(err);
					subscriber.complete();
				})
		})
	)
}
