import React from 'react';
import { withRouter } from 'react-router';
import { StaticArtworkSnapshotBox } from '../../../../ads-components/boxes/artwork-snapshot-box/static/StaticArtworkSnapshotBox';
import { MaxTextLinesBox } from '../../../../ads-components/boxes/max-text-lines-box/MaxTextLinesBox';
import { SquareImageBox } from '../../../../ads-components/boxes/square-image-box/SquareImageBox';
import { ColumnVerticalContainer } from '../../../../ads-components/containers/grids/columns/ColumnVerticalContainer';
import { FullScreenModal } from '../../../../ads-components/modals/full-screen/FullScreenModal';
import { EmptyStateIcon } from '../../../../configs';
import { AppContext } from '../../../../context/context';
import { AlbumViewModel, WithRouterProps } from '../../../../models';
import {
	formatArtworkCreationDate,
	getArtworkImageSrc,
	getImageAssetUrl,
	scrollToPosition,
} from '../../../../utils';
import { BackLinkButton } from '../../../details/views/large-landscape/styles';
import { ExpirationIndicator, PostIndicator } from '../../../generic';
import { IconsStatusBarContainer } from '../../../generic/icon-status-bar-container/IconStatusBarContainer';
import { LoadMoreBar } from '../../../generic/load-more-bar/LoadMoreBar';
import { ViewActionsPortal } from '../../../generic/view-actions-portal/ViewActionsPortal';
import { actionStyle } from '../../../top-bar/styles';
import { artworkPreviewClick } from '../../utils';
import { IndicatorsContainer } from '../small-portrait/styles';
import {
	ArtworkContent,
	ArtworkContentContainer,
	ArtworkListElement,
	CloseButton,
	ComponentStyle,
	HomeWrapper,
	NoArtworkInfo,
	NoArtworksContainer,
	NoArtworksContainerWithImage,
	SquareImageContainer,
	StyledSeparator,
} from './styles';

const headerHeight = '100%';
const descriptionMaxLinesCount = 3;
const cs = new ComponentStyle(headerHeight);
const homeWrapperId = 'home-wrapper';

type State = {
	showModal: boolean;
};

interface Props extends AlbumViewModel, WithRouterProps { }

class HomeLargeLandscapeBase extends React.Component<Props, State> {
	static contextType = AppContext;

	imageContainerRef = React.createRef<HTMLDivElement>();

	setScrollPosition = () => {
		this.context.viewDataService.setHomeScrollPosition(
			this.props.uuid,
			document.getElementById(homeWrapperId)!.scrollTop
		);
	};

	getArtworks = () => {
		return this.props.artworks.map((artwork, index, array) => {
			const creationYear =
				artwork.creation &&
					artwork.creation.date &&
					artwork.creation.date.start.value &&
					artwork.creation.date.start.format
					? formatArtworkCreationDate(artwork.creation.date, 'YYYY', false)
					: null;

			return (
				<div
					className="hoverable-image-opacity"
					onClick={() => {
						this.setScrollPosition();

						artworkPreviewClick(
							this.props.isPrivate,
							this.props.history,
							this.props.uuid,
							artwork._id
						);
					}}
					key={index}
					style={new ArtworkListElement(index !== array.length - 1)}>
					<StaticArtworkSnapshotBox
						imageSrc={getArtworkImageSrc(artwork.previews, getImageAssetUrl)}
						description={{
							artist: artwork.authors.length ? artwork.authors[0].name.full : 'Unknown',
							title: artwork.titles[0].value,
							...(creationYear && { creationYear: creationYear }),
						}}
					/>
				</div>
			);
		});
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			showModal: false,
		};
	}

	componentDidMount() {
		setTimeout(() =>
			scrollToPosition(
				homeWrapperId,
				0,
				this.context.viewDataService.data.home[this.props.uuid] || 0
			)
		);
	}

	render() {
		if (!this.state) {
			return null;
		}

		const image = this.props.coverImageSrc === EmptyStateIcon.ARTWORK;
		return (
			<HomeWrapper id={homeWrapperId} style={{ padding: '0 6vw', position: 'relative' }}>
				<div style={{ position: 'unset' }}>
					<FullScreenModal visible={this.state.showModal}>
						<CloseButton>
							<img
								onClick={() =>
									this.setState({
										showModal: false,
									})
								}
								style={{ cursor: 'pointer' }}
								src="/icons/ico-showroom-close.svg"
								alt="close"
							/>
						</CloseButton>
						<ArtworkContentContainer>
							{!image ? (
								<SquareImageContainer>
									<SquareImageBox
										imageSrc={this.props.coverImageSrc}
										boxSideSize="25vh"
									/>
								</SquareImageContainer>
							) : null}
							<ArtworkContent>
								<h1
									style={{
										...cs.header.leftTitle,
										display: 'block',
									}}>
									{this.props.title}
								</h1>

								<p
									style={{
										fontSize: cs.header.leftDescription.fontSize,
										lineHeight: cs.header.leftDescription.lineHeight,
									}}>
									{this.props.description}
								</p>
							</ArtworkContent>
						</ArtworkContentContainer>
					</FullScreenModal>
				</div>
				<div
					style={{
						display: !this.state.showModal ? 'block' : 'none',
						overflowY: 'visible',
						position: 'relative',
					}}>
					<div id="header-wrapper" style={
						!image
							? cs.header.wrapperContainer
							: cs.header.wrapperContainer2
					}>
						<div ref={this.imageContainerRef} style={cs.header.container}>
							<div
								id="header-left-container"
								style={
									!image
										? cs.header.leftContainer
										: cs.header.leftContainerNoImage
								}>
								<IndicatorsContainer>
									<PostIndicator postedOn={this.props.postedOn} />
									{this.props.expiresOn && (
										<StyledSeparator> | </StyledSeparator>
									)}
									<ExpirationIndicator expiresOn={this.props.expiresOn} />
								</IndicatorsContainer>

								<h1 style={cs.header.leftTitle}>{this.props.title}</h1>

								{this.props.description ?
									<p style={cs.header.leftDescription}>
										<MaxTextLinesBox
											showReadMoreIndicator={true}
											readMoreClick={() =>
												this.setState({
													showModal: true,
												})
											}
											maxLinesCount={descriptionMaxLinesCount}>
											{this.props.description}
										</MaxTextLinesBox>
									</p>
									: null}

								<IconsStatusBarContainer
									albumUuid={this.props.uuid}
									likes={this.props.likes}
									timesVisited={this.props.timesVisited}
									artworksCount={this.props.artworksCount}
								/>
							</div>

							{!image ? (
								<div style={{ minWidth: '60vh', minHeight: '60vh' }}>
									<SquareImageBox
										imageSrc={this.props.coverImageSrc}
										boxSideSize={headerHeight}
									/>
								</div>
							) : null}
						</div>
					</div>

					{this.props.artworksCount > 0 ? (
						<ColumnVerticalContainer numberOfColumns={4} columnSpacing="1.5vw">
							{this.getArtworks()}
						</ColumnVerticalContainer>
					) : image ? (
						<NoArtworksContainer>
							<NoArtworkInfo>No artworks</NoArtworkInfo>
						</NoArtworksContainer>
					) : (
						<NoArtworksContainerWithImage>
							<NoArtworkInfo>No artworks</NoArtworkInfo>
						</NoArtworksContainerWithImage>
					)}

					{/* {this.props.artworksCount > this.props.artworks.length ? (
						<LoadMoreBar click={() => this.props.loadMoreArtworks!()} />
					) : null} */}
				</div>

				<ViewActionsPortal>
					<BackLinkButton
						onClick={() => {
							this.setScrollPosition();

							this.props.history.push('/');
						}}
						style={actionStyle.navigationButton}>
						<img
							style={actionStyle.arrow}
							src="/icons/ico-showroom-arrow-left.svg"
						/>
						<span style={actionStyle.text}>All showrooms</span>
					</BackLinkButton>
				</ViewActionsPortal>
			</HomeWrapper>
		);
	}
}

export const HomeLargeLandscape = withRouter(HomeLargeLandscapeBase);
