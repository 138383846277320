import {
	AlignItems,
	Display,
	JustifyContent,
	Position,
	Cursor,
} from '../../../../../../ads-components/models';
import { ComponentStyleModel } from '../models';
import { defaultBackgroundColor } from '../../../../../../styles';
import styled from 'styled-components';

export class ComponentStyle implements ComponentStyleModel {
	container = {
		marginBottom: '8vh',
	};

	header = {
		position: Position.Relative,
		display: Display.Flex,
		justifyContent: JustifyContent.SpaceBetween,
		cursor: Cursor.Pointer,
		minHeight: '44vw',
		minWidth: '88vw'
	};

	forSaleTag = {
		position: Position.Absolute,
		margin: '0.3rem',
		color: '#565EE2',
	};

	albumItems = {
		display: Display.Grid,
		gridGap: '',
		padding: '',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gridTemplateRows: 'repeat(2, 1fr)',
		width: '100%',
		maxWidth: '47.1vw',
	};

	albumTwoItems = {
		display: Display.Grid,
		gridGap: '',
		padding: '',
		gridTemplateColumns: 'repeat(1, 1fr)',
		gridTemplateRows: 'repeat(2, 1fr)',
		width: '100%',
		maxWidth: '47.1vw',
	};

	content = {
		marginTop: '2rem',
	};

	infoBar = {
		color: 'gray',
		display: '-webkit-box',
		justifyContent: 'space-between'
	};

	iconsBar = {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		justifyContent: JustifyContent.SpaceBetween,
	};

	constructor(headerHeight: string, gridGap: string) {
		this.albumItems.gridGap = gridGap = '0.1rem';
		this.albumItems.padding = '0';
	}
}

const SquareImageContainer = styled.div`
	width: 100%;
`;

const OneImageContainer = styled.div`
	min-width: 100%;
`;

const TwoImagesContainer = styled.div`
	min-width: 75%;
`;

const NoArtworksIndicator = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f9f9f9;
	color: #959699;
	font-style: italic;
	width: 100%;
	text-align: center;
`;

const ButtonContainer = styled.div`
	height: 4rem;
	width: 15rem;
	margin-top: 2rem;
`;
export {
	SquareImageContainer,
	NoArtworksIndicator,
	ButtonContainer,
	OneImageContainer,
	TwoImagesContainer
}
