export enum CurrencyType {
	AED = 'AED',
	AUD = 'AUD',
	BRL = 'BRL',
	CAD = 'CAD',
	CHF = 'CHF',
	CNY = 'CNY',
	EUR = 'EUR',
	GBP = 'GBP',
	HKD = 'HKD',
	IDR = 'IDR',
	INR = 'INR',
	JPY = 'JPY',
	KES = 'KES',
	KRW = 'KRW',
	MAD = 'MAD',
	NGN = 'NGN',
	MXN = 'MXN',
	NOK = 'NOK',
	NZD = 'NZD',
	PLN = 'PLN',
	RUB = 'RUB',
	SEK = 'SEK',
	SGD = 'SGD',
	TRY = 'TRY',
	USD = 'USD',
	ZAR = 'ZAR',
}
