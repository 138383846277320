import {
	AlignItems,
	Display,
	JustifyContent,
	Position,
	Cursor,
} from '../../../../../../ads-components/models';
import { ComponentStyleModel } from '../models';
import { defaultBackgroundColor } from '../../../../../../styles';
import styled from 'styled-components';
import Breakpoints from '../../../../../../styles/Breakpoints';

export class ComponentStyle implements ComponentStyleModel {
	container = {
		margin: '0 0 4.6rem 0',
	};

	header = {
		position: Position.Relative,
		display: Display.Flex,
		justifyContent: JustifyContent.SpaceBetween,
		cursor: Cursor.Pointer,
	};

	forSaleTag = {
		position: Position.Absolute,
		margin: '0.3rem',
		color: '#565EE2',
	};

	albumItems = {
		display: Display.Grid,
		gridGap: '',
		padding: '',
		gridTemplateColumns: 'repeat(1, 1fr)',
		gridTemplateRows: 'repeat(3, 1fr)',
		width: '100%',
	};

	content = {
		marginTop: '1rem',
	};

	infoBar = {
		color: 'gray',
		display: '-webkit-box',
		justifyContent: 'space-between',
	};

	iconsBar = {
		display: Display.Flex,
		alignItems: AlignItems.Center,
		justifyContent: JustifyContent.SpaceBetween,
	};

	constructor(headerHeight: string, gridGap: string) {
		this.albumItems.gridGap = gridGap = '0.1rem';
		this.albumItems.padding = '0';
	}
}

const SquareImageContainer = styled.div`
	min-width: 77%;
	padding-right: 0.1rem;
`;

const NoArtworksIndicator = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	background: #f9f9f9;
	color: #959699;
	font-style: italic;
	width: 100%;
	text-align: center;
	min-height: 55vw;
`;

const ButtonContainer = styled.div`
	height: 4rem;
	width: 15rem;
	margin-top: 1rem;

	& > button {
		border-radius: 25px;

		@media all and (max-width: ${Breakpoints.phone}) {
			height: 4.2rem;
		}
	}
`;
export { SquareImageContainer, NoArtworksIndicator, ButtonContainer };
