export enum JustifyContent {
    Start = 'start',
    FlexStart = 'flex-start',
    Center = 'center',
    End = 'end',
    FlexEnd = 'flex-end',
    Left = 'left',
    Right = 'right',
    SpaceBetween = 'space-between',
    SpaceAround = 'space-around',
    SpaceEvenly = 'space-evenly',
    Stretch = 'stretch'
}
