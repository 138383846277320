import { Display, BoxSizing, Position } from "../../../models";

export class componentStyle {
  display: Display;
  position = Position.Fixed;
  background = "white";
  top = "0";
  left = "0";
  height = "100%";
  width = "100%";

  constructor(visible: boolean) {
    this.display = visible ? Display.Block : Display.None;
  }
}
