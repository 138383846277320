import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/context';
import { ViewState } from '../../models';
import { Album, Artwork } from '../../services/models/api-models';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { LogoIndicator } from '../../ads-components/indicators/logo-loading-indicator/LogoLoadingIndicator';
import { defaultLogoIndicatorSize } from '../../styles';
import { Effect } from '../../ads-components/indicators/logo-loading-indicator/models';
import { ViewportType } from '../../context/models';
import { Props } from '../details/models';
import { DetailsLargeLandscape, DetailsSmallPortrait } from '../details/views';
import { DetailsMediumLandscape } from '../details/views/medium-landscape/DetailsMediumLandscape';
import { DetailsMediumPortrait } from '../details/views/medium-portrait/DetailsMediumPortrait';
import { Subscription } from 'rxjs';

function getView(viewportType: ViewportType, props: Props) {
	if (viewportType === +ViewportType.SmallPortrait) {
		return <DetailsSmallPortrait {...props} />;
	} else if (
		viewportType === +ViewportType.SmallLandscape ||
		viewportType === +ViewportType.MediumLandscape
	) {
		return <DetailsMediumLandscape {...props} />;
	} else if (viewportType === +ViewportType.MediumPortrait) {
		return <DetailsMediumPortrait {...props} />;
	} else {
		return <DetailsLargeLandscape {...props} />;
	}
}

interface Params {
	uuid: string | undefined;
	artworkId: string | undefined;
	previewIndex: string | undefined;
}

interface LocationState {
	showroom: Album<Artwork> | undefined
}

export function PrivateDetails(): JSX.Element{
	const componentSubs = new Subscription();
	const context = useContext(AppContext);
	const params = useParams<Params>();
	const history = useHistory();
	const location = useLocation<LocationState>();
	const match = useRouteMatch();
	const [viewState, setViewState] = useState<ViewState>(ViewState.Loading);
	const [album, setAlbum] = useState<Album<Artwork> | null>(null);
	const [previewIndex, setPreviewIndex] = useState<number>(0);
	const [artworkIndex, setArtworkIndex] = useState<number>(0);

	function updateView(album: Album<Artwork>) {
		// NOTE: here we have to verify if album contains artwork and correct artwork preview has been selected
		const albumArtworkIndex: number = album.artworks.findIndex((artwork: Artwork) => artwork._id === params.artworkId);
		if(albumArtworkIndex !== -1) {
			if((+params.previewIndex > 0 && +params.previewIndex < album.artworks[albumArtworkIndex].previews.length) || params.previewIndex === '0'){
				setPreviewIndex(+params.previewIndex);
				setArtworkIndex(albumArtworkIndex);
				setViewState(ViewState.Ok);
			} else {
				setViewState(ViewState.Error);
			}
		} else {
			setViewState(ViewState.Error);
		}
	}

	useEffect(() => {
		if(location.state && location.state.showroom){
			// NOTE: here we verify whether showroom hasn't been unpublished
			setViewState(ViewState.Loading);
			componentSubs.add(context.showroomService.privateShowroomExistsObs(location.state.showroom.uuid).subscribe(
				() => {
					setAlbum(location.state.showroom as Album<Artwork>);
					updateView(location.state.showroom as Album<Artwork>);
					setViewState(ViewState.Ok);
				},
				() => { history.push('/private/404'); },
			))
		} else {
			history.push(`/private/${params.uuid}/login`, {
				returnPath: `/private/${params.uuid}/${params.artworkId}/${params.previewIndex}`,
			})
		}
	}, [location.state]);

	useEffect(() => {
		// NOTE: below changes will be applied only when showroom exist
		if(params.previewIndex && album) {
			const previewsLength: number = album!.artworks[artworkIndex].previews.length;
			if(+params.previewIndex < previewsLength){
				setPreviewIndex(+params.previewIndex);
			} else {
				setViewState(ViewState.Error);
			}
		}
	}, [params.previewIndex]);

	useEffect(() => {
		// NOTE: below changes will be applied only when showroom exist
		if(params.artworkId && album){
			const artworksIds: string[] = album.artworks.map((artwork) => artwork._id);
			if(artworksIds.includes(params.artworkId)) {
				setArtworkIndex(artworksIds.indexOf((params.artworkId)));
			} else {
				setViewState(ViewState.Error);
			}
		}
	}, [params.artworkId])

	useEffect(() => {
		return () => {
			if(componentSubs) {
				componentSubs.unsubscribe();
			}
		}
	}, []);

	function changeArtwork(
		direction: 'next' | 'previous',
		changePreview: boolean,
		// NOTE: Current artwork preview index before change
		previewIndex: number | null
	) {
		if(album) {
			if(changePreview && typeof previewIndex === 'number') {
				history.push(`/private/${params.uuid}/${params.artworkId}/${previewIndex}`, { ...history.location.state });
			} else {
				const albumArtworksLength: number = album.artworks.length;
				const currentArtworkIndex = album.artworks.findIndex((artwork: Artwork) => artwork._id === params.artworkId);
				let newArtworkIndex: number | null = null;

				if(direction === 'next') {
					if(currentArtworkIndex + 1 < albumArtworksLength) {
						newArtworkIndex = currentArtworkIndex + 1;
					}
				}

				if(direction === 'previous') {
					if(currentArtworkIndex - 1 >= 0){
						newArtworkIndex = currentArtworkIndex - 1;
					}
				}

				if(newArtworkIndex !== null) {
					const newArtworkId = album.artworks[newArtworkIndex]._id;
					history.push(`/private/${params.uuid}/${newArtworkId}/0`, {...history.location.state});
				}
			}
		}
	}

	return (
		<>
			{viewState === ViewState.Loading && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						flex: '1',
					}}>
					<LogoIndicator
						width={defaultLogoIndicatorSize}
						effect={Effect.Pulse}
					>
						{viewState}
					</LogoIndicator>
				</div>
			)}
			{viewState === ViewState.Error && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						flex: '1',
					}}>
					<LogoIndicator
						width={defaultLogoIndicatorSize}
						effect={Effect.Static}
					>
						{viewState}
					</LogoIndicator>
				</div>
			)}
			{viewState === ViewState.Ok && (
				getView(
					context.viewportType,
					{
						albumName: album!.name,
						artwork: album!.artworks[artworkIndex],
						previewIndex,
						albumArtworkCount: album!.artworksCount,
						changeArtworkFn: changeArtwork,
						private: true,
						history,
						location,
						match
					}
				)
			)}
		</>
	)
}
