import  { Languages } from './languages.model';

export interface Labels {
	[Languages.ar]?: string;
	[Languages.cz]?: string;
	[Languages.de]?: string;
	[Languages.en]: string;
	[Languages.es]?: string;
	[Languages.fr]?: string;
	[Languages.ger]?: string;
	[Languages.it]?: string;
	[Languages.jp]?: string;
	[Languages.pl]?: string;
	[Languages.ru]?: string;
	[Languages.kor]?: string;
	[Languages.nld]?: string;
}
