import React from 'react';
import { StaticImageBox } from '../../../ads-components/boxes/static-image-box/StaticImageBox';
import { Measurement } from '../../../ads-components/boxes/static-image-box/models';

export function getPublicPreview(imageUrl: string, measurement: Measurement) {
	return (
		<StaticImageBox
			imageSrc={imageUrl}
			measurement={measurement}></StaticImageBox>
	);
}
